import { useState } from "react";
import { useAuth } from "../../../../frontend/src/context/AuthContext";
import { isTokenExpired } from "../../../../frontend/src/utils/jwtUtils";
import refreshAccessToken from "../../../../frontend/src/utils/refreshToken";
const listAttachmentHook = (baseUrl) => {
  const { accessToken, refreshToken, updateTokens, logout } = useAuth();
  const [searchResults, setSearchResults] = useState([]);
  const [count, setCount] = useState(null);
  const [error, setError] = useState("");

  const listAttachments = async (
    category = "",
    mod = "",
    companyMod = "",
    subCategoryMod = "",
    modelNum = "",
    page = 1,
    limit = 50
  ) => {
    let currentAccessToken = accessToken;

    // Check and refresh the token if it's expired
    if (!currentAccessToken || isTokenExpired(currentAccessToken)) {
      const refreshedTokenData = await refreshAccessToken(
        baseUrl,
        refreshToken,
        updateTokens,
        logout
      );
      if (!refreshedTokenData) {
        // Instead of navigating directly, call the logout function
        logout("Your session has expired. Please log in again.");
        return;
      }
      currentAccessToken = refreshedTokenData.accessToken;
    }

    setError(""); // Clear any existing error message
    setSearchResults([]); // Clear previous search results

    try {
      let url = `${baseUrl}/api/listAttachment/?category=${category}`;
      if (mod) {
        url += `&mod=${mod}`;
      }
      if (companyMod) {
        url += `&companyMod=${companyMod}`;
      }
      if (subCategoryMod) {
        url += `&subCategoryMod=${subCategoryMod}`;
      }
      if (modelNum) {
        url += `&modelNum=${modelNum}`;
      }
      if (page) {
        url += `&page=${page}`;
      }
      if (limit) {
        url += `&limit=${limit}`;
      }
      const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentAccessToken}`,
        },
      });
      const data = await response.json();

      if (response.ok) {
        if (data.data && data.data.attachments) {
          setSearchResults(data.data.attachments);
          setCount(data.data.totalCount);
        } else if (data.message) {
          // Handle the case where no attachments are found and a message is provided
          setError(data.message);
          setSearchResults([]); // Ensure search results are explicitly set to empty
          setCount(0);
        } else {
          // Handle unexpected cases where no data and no attachments key is found
          setError("No attachments found and no message provided.");
        }
      } else {
        // Handle API errors with a generic message if specific errors aren't provided
        const errorMessage = data.errors?.length
          ? data.errors.join(", ")
          : "Failed to fetch results";
        setError(errorMessage);
      }
    } catch (err) {
      console.log(err);
      setError("Network error 201 or unable to connect to the server", err);
    }
  };

  return {
    count,
    searchResults,
    error,
    listAttachments,
  };
};

export default listAttachmentHook;
