import React from 'react';
import { Modal, Button, Carousel } from 'react-bootstrap';
import PropTypes from 'prop-types';

function PartDetailsModal({
  show,
  onHide,
  currentPart,
  currentPartImages,
  openMessageModal,
  partMessages
}) {
  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ width: '100%', textAlign: 'center' }}>
          {currentPart.descript || currentPart.description} for{' '}
          {currentPart.manuflong}{' '}
          {currentPart.partnum || currentPart.partNumber}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentPartImages.length > 1 && (
          <Carousel>
            {currentPartImages.map((img, index) => (
              <Carousel.Item key={img.picId}>
                <img
                  className="d-block w-100"
                  src={img.url}
                  alt={`Slide ${index}`}
                  onError={e => {
                    e.target.src = '/images/image_not_available.png';
                  }}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        )}
        {currentPartImages.length === 1 && (
          <img
            className="d-block w-100"
            src={currentPartImages[0].url}
            alt="pic"
            onError={e => {
              e.target.src = '/images/image_not_available.png';
            }}
          />
        )}
        {currentPart && (
          <div style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap' }}>
            <p style={{ width: '100%', textAlign: 'center' }}>
              <strong>Part Number:</strong>{' '}
              {currentPart.partnum || currentPart.partNumber}
            </p>
            <p style={{ flex: '1 0 50%', padding: '10px' }}>
              <strong>Company Name:</strong>{' '}
              {currentPart.companyname || currentPart.companyName}
            </p>
            <p style={{ flex: '1 0 50%', padding: '10px' }}>
              <strong>Description:</strong>{' '}
              {currentPart.descript || currentPart.description}
            </p>
            <p style={{ flex: '1 0 50%', padding: '10px' }}>
              <strong>Condition:</strong> {currentPart.condlong}
            </p>
            <p style={{ flex: '1 0 50%', padding: '10px' }}>
              <strong>Manufacturer:</strong> {currentPart.manuflong}
            </p>
            <p style={{ flex: '1 0 50%', padding: '10px' }}>
              <strong>Quantity:</strong> {currentPart.quan}
            </p>
            <p style={{ flex: '1 0 50%', padding: '10px' }}>
              <strong>Price:</strong>{' '}
              {currentPart.usedprice || currentPart.quotedPrice
                ? `$${currentPart.usedprice || currentPart.quotedPrice}`
                : 'Contact for price'}
            </p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {partMessages[currentPart.id] && (
          <p style={{ color: 'green', marginTop: '5px' }}>
            {partMessages[currentPart.id]}
          </p>
        )}
        <Button variant="primary" onClick={() => openMessageModal(currentPart)}>
          Send Message
        </Button>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
PartDetailsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  currentPart: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  currentPartImages: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      picId: PropTypes.number.isRequired
    })
  ).isRequired,
  openMessageModal: PropTypes.func.isRequired,
  partMessages: PropTypes.objectOf(PropTypes.string).isRequired
};
export default PartDetailsModal;
