import { useState } from "react";

const listAttachmentHook = (baseUrl) => {
  const [searchResults, setSearchResults] = useState([]);
  const [count, setCount] = useState(null);
  const [error, setError] = useState("");

  const listAttachments = async (
    category = "",
    mod = "",
    subCategoryMod = "",
    modelNum = "",
    page = 1,
    limit = 50
  ) => {
    setError(""); // Clear any existing error message
    setSearchResults([]); // Clear previous search results

    try {
      let url = `${baseUrl}/api/guestlistattachment/?category=${category}`;
      if (mod) {
        url += `&mod=${mod}`;
      }
      if (subCategoryMod) {
        url += `&subCategoryMod=${subCategoryMod}`;
      }
      if (modelNum) {
        url += `&modelNum=${modelNum}`;
      }
      if (page) {
        url += `&page=${page}`;
      }
      if (limit) {
        url += `&limit=${limit}`;
      }
      const response = await fetch(url);
      const data = await response.json();

      if (response.ok) {
        if (data.data && data.data.attachments) {
          setSearchResults(data.data.attachments);
          setCount(data.data.totalCount);
        } else if (data.message) {
          // Handle the case where no attachments are found and a message is provided
          setError(data.message);
          setSearchResults([]); // Ensure search results are explicitly set to empty
          setCount(0);
        } else {
          // Handle unexpected cases where no data and no attachments key is found
          setError("No attachments found and no message provided.");
        }
      } else {
        // Handle API errors with a generic message if specific errors aren't provided
        const errorMessage = data.errors?.length
          ? data.errors.join(", ")
          : "Failed to fetch results";
        setError(errorMessage);
      }
    } catch (err) {
      console.log(err);
      setError("Network error 201 or unable to connect to the server", err);
    }
  };

  return {
    count,
    searchResults,
    error,
    listAttachments,
  };
};

export default listAttachmentHook;
