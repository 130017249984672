import { useState, useEffect } from "react";

const manufListHook = (baseUrl) => {
  const [manufList, setManufList] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchManufacturers = async () => {
      setError("");
      setManufList([]);

      try {
        let url = `${baseUrl}/api/manufList/`;
        const response = await fetch(url);
        const data = await response.json();

        if (response.ok) {
          if (data.data) {
            setManufList(data.data || []);
          } else if (data.message) {
            setError(data.message);
          } else {
            setError("No manufactures found and no message provided.");
          }
        } else {
          const errorMessage = data.errors?.length
            ? data.errors.join(", ")
            : "Failed to fetch results";
          setError(errorMessage);
        }
      } catch (err) {
        setError("Network error 101 or unable to connect to the server");
      }
    };

    fetchManufacturers();
  }, []); // Empty dependency array means this effect will only run once

  return {
    manufList,
    error,
  };
};

export default manufListHook;
