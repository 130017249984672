import React, { useEffect, useState } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import filterListHook from '@netcom-network-v2/shared/src/hooks/filterListHook';
import listAttachmentHook from '@netcom-network-v2/shared/src/hooks/listAttachmentHook';
import AttachmentSearch from './AttachmentDetails';

function ListAttachment({ category }) {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { searchResults, error, count, listAttachments } =
    listAttachmentHook(baseUrl);
  const { companyList, subCategoryList, filterList } = filterListHook(baseUrl);

  const [sortConfig, setSortConfig] = useState({
    field: null,
    direction: null
  });
  const [modelNum, setModelNum] = useState('');
  const [companyMod, setCompanyMod] = useState('');
  const [subCategoryMod, setSubCategoryMod] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 50;
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 576);
  const totalPages = Math.ceil(count / limit);
  const [showModal, setShowModal] = useState(false);
  const [selectedAttachmentId, setSelectedAttachmentId] = useState(null);
  const handleRowClick = attachmentId => {
    setSelectedAttachmentId(attachmentId);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAttachmentId(null);
  };
  useEffect(() => {
    const sortParam =
      sortConfig.field && sortConfig.direction
        ? `${sortConfig.field}${sortConfig.direction}`
        : null;

    filterList(companyMod, subCategoryMod, category);

    listAttachments(
      category,
      sortParam,
      companyMod !== 'All Companies' ? companyMod : undefined,
      subCategoryMod !== 'All Categories' ? subCategoryMod : undefined,
      modelNum,
      currentPage,
      limit
    );
  }, [category, sortConfig, companyMod, subCategoryMod, modelNum, currentPage]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 576);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleInputChange = e => {
    const formattedInput = e.target.value
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, '');
    setModelNum(formattedInput);
    setCurrentPage(1); // Reset to first page when modelNum changes
  };

  const handleSort = field => {
    setSortConfig(prevConfig => {
      if (prevConfig.field === field) {
        return {
          field,
          direction: prevConfig.direction === 'asc' ? 'desc' : 'asc'
        };
      }
      return { field, direction: 'asc' };
    });
  };

  const getSortIcon = field => {
    if (sortConfig.field === field) {
      return sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />;
    }
    return null;
  };

  const handlePageChange = page => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
      window.scrollTo(0, 0);
    }
  };
  const handleModalEntering = () => {
    const modalDialog = document.querySelector('.modal-dialog');
    if (modalDialog) {
      modalDialog.style.maxWidth = '1600px'; // Set desired max-width
      modalDialog.style.width = '80%'; // Set desired width
      modalDialog.style.margin = 'auto'; // Center the modal
    }
  };
  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div className="row" style={{ marginBottom: '20px' }}>
          <div className="col-8 col-lg-4">
            <p style={{ marginBottom: '5px', fontWeight: 500 }}>
              Search By Model Number
            </p>
            <input
              type="text"
              value={modelNum}
              onChange={handleInputChange}
              placeholder="Enter Model Number"
              style={{ width: '275px', height: '33px', padding: '2' }}
            />
          </div>
          {![101, 104, 105, 106].includes(category) && (
            <div className="col-6 col-lg-3">
              <p style={{ marginBottom: '5px', fontWeight: 500 }}>
                Filter By Category
              </p>
              <select
                value={subCategoryMod}
                onChange={e => setSubCategoryMod(e.target.value)}
                style={{
                  width: '200px',
                  padding: '5px 10px'
                }}
              >
                <option value="All Categories">All Categories</option>
                {subCategoryList &&
                  subCategoryList.map(subCategories => (
                    <option
                      key={subCategories.subcat_name}
                      value={subCategories.subcat_name}
                    >
                      {subCategories.subcat_name}
                    </option>
                  ))}
              </select>
            </div>
          )}
          <div className="col-6 col-lg-3">
            <p style={{ marginBottom: '5px', fontWeight: 500 }}>
              Filter By Company
            </p>
            <select
              value={companyMod}
              onChange={e => setCompanyMod(e.target.value)}
              style={{
                width: '200px',
                padding: '5px 10px'
              }}
            >
              <option value="All Companies">All Companies</option>
              {companyList &&
                companyList.map(company => (
                  <option key={company.companyname} value={company.companyname}>
                    {company.companyname}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="row" style={{ marginBottom: '15px' }}>
          <p style={{ marginBottom: '5px', fontWeight: 500 }}>
            Displaying page {currentPage} of {totalPages}, total results:{' '}
            {count}
            {[
              modelNum && ` a Model Number that begins with ${modelNum}`,
              companyMod &&
                companyMod !== 'All Companies' &&
                ` from the Company: ${companyMod}`,
              subCategoryMod &&
                subCategoryMod !== 'All Categories' &&
                ` from the Category: ${subCategoryMod}`
            ]
              .filter(Boolean)
              .join(', ')
              .replace(/, ([^,]*)$/, ', and $1')}
          </p>
        </div>

        <Table className="table-bordered table-striped">
          <thead>
            <tr>
              <th
                className={`ForSaleth ${isSmallScreen ? 'hide-on-small-screen' : ''}`}
              >
                CompanyName
              </th>
              <th className="ForSaleth">Manufacturer</th>
              <th
                className="sortable-header ForSaleth"
                onClick={() => handleSort('model')}
              >
                Model Number {getSortIcon('model')}
              </th>
              <th className="ForSaleth">Condition</th>
              {![101, 104, 105, 106].includes(category) && (
                <th
                  className={`ForSaleth ${isSmallScreen ? 'hide-on-small-screen' : ''}`}
                >
                  Category
                </th>
              )}
              <th
                className="sortable-header ForSaleth"
                onClick={() => handleSort('price')}
              >
                Price {getSortIcon('price')}
              </th>
              <th
                className="sortable-header ForSaleth"
                onClick={() => handleSort('date')}
              >
                Date Modified {getSortIcon('date')}
              </th>
            </tr>
          </thead>
          <tbody>
            {searchResults?.map(attachment => (
              <tr
                key={attachment.id}
                onClick={() => handleRowClick(attachment.id)}
              >
                <td
                  className={`${isSmallScreen ? 'hide-on-small-screen' : ''}`}
                >
                  {attachment.companyname}
                </td>
                <td>{attachment.manuflong}</td>
                <td className="clickable-column">{attachment.modelnum}</td>
                <td>{attachment.condlong}</td>
                {![101, 104, 105, 106].includes(category) && (
                  <td
                    className={`${isSmallScreen ? 'hide-on-small-screen' : ''}`}
                  >
                    {attachment.subcategory}
                  </td>
                )}
                <td>{attachment.price ? `$${attachment.price}` : 'N/A'}</td>
                <td
                  className={`${isSmallScreen ? 'hide-on-small-screen' : ''}`}
                >
                  {attachment.datemod}
                </td>
              </tr>
            ))}
            {error && (
              <tr>
                <td colSpan="7">Error: {error}</td>
              </tr>
            )}
            {showModal && selectedAttachmentId && (
              <Modal
                show={showModal}
                onHide={handleCloseModal}
                size="xl"
                aria-labelledby="attachment-details-modal"
                onEntering={handleModalEntering}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title id="attachment-details-modal" />
                </Modal.Header>
                <Modal.Body>
                  <AttachmentSearch attachmentId={selectedAttachmentId} />
                  <div className="d-flex justify-content-end mt-3">
                    <Button variant="secondary" onClick={handleCloseModal}>
                      Close
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            )}
          </tbody>
        </Table>
        <div className="row" style={{ marginBottom: '5px' }}>
          <div className="col-3 col-lg-4 d-flex justify-content-start">
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt; Previous
            </Button>
          </div>
          <div className="col-3 col-lg-4 d-flex justify-content-center">
            <span>
              Page {currentPage} of {totalPages}
            </span>
          </div>
          <div className="col-3 col-lg-4 d-flex justify-content-end ">
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              style={{ paddingLeft: '30px', paddingRight: '25px' }}
            >
              Next &gt;
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

ListAttachment.propTypes = {
  category: PropTypes.number.isRequired
};

export default ListAttachment;
