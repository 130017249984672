import React from 'react';
import { Carousel } from 'react-bootstrap';
import '../App.css'; // Import the global CSS file

function GalleriesCarousel() {
  return (
    <div>
      <h2>Galleries</h2>
      <Carousel indicators={false}>
        <Carousel.Item>
          <a href="/galleries/thompsonmachinery">
            <img
              src="/images/galleries/gallery/Gallery_1.jpeg"
              alt="Thompson Machinery"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Thompson Machinery</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/thompsontractor">
            <img
              src="/images/galleries/gallery/Gallery_25.jpg"
              alt="Thompson Tractor"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Thompson Tractor</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/yellowstonevalley">
            <img
              src="/images/galleries/gallery/Gallery_2.jpeg"
              alt="Yellowstone Valley Parts"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Yellowstone Valley Parts</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/wellertractor">
            <img
              src="/images/galleries/gallery/Gallery_3.jpeg"
              alt="Weller Tractor Salvage"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Weller Tractor Salvage</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/boydandsons">
            <img
              src="/images/galleries/gallery/Gallery_4.png"
              alt="Boyd and Sons"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Boyd and Sons</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/ironking">
            <img
              src="/images/galleries/gallery/Gallery_5.jpeg"
              alt="Iron King"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Iron King</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/billmiller">
            <img
              src="/images/galleries/gallery/Gallery_6.jpeg"
              alt="Bill Miller Equipment"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Bill Miller Equipment</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/heavyquip">
            <img
              src="/images/galleries/gallery/Gallery_7.jpeg"
              alt="Heavy Quip Inc."
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Heavy Quip Inc.</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/altorfer">
            <img
              src="/images/galleries/gallery/Gallery_8.jpeg"
              alt="Altorfer Cat"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Altorfer Cat</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/thompsonmachinery">
            <img
              src="/images/galleries/gallery/Gallery_24.jpeg"
              alt="Thompson Machinery"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Thompson Machinery</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/thompsontractor">
            <img
              src="/images/galleries/gallery/Gallery_26.jpg"
              alt="Thompson Tractor"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Thompson Tractor</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/yellowstonevalley">
            <img
              src="/images/galleries/gallery/Gallery_9.jpeg"
              alt="Yellowstone Valley Parts"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Yellowstone Valley Parts</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/wellertractor">
            <img
              src="/images/galleries/gallery/Gallery_10.jpeg"
              alt="Weller Tractor Salvage"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Weller Tractor Salvage</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/boydandsons">
            <img
              src="/images/galleries/gallery/Gallery_11.jpeg"
              alt="Boyd and Sons"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Boyd and Sons</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/ironking">
            <img
              src="/images/galleries/gallery/Gallery_12.jpeg"
              alt="Iron King"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Iron King</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/billmiller">
            <img
              src="/images/galleries/gallery/Gallery_13.jpeg"
              alt="Bill Miller Equipment"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Bill Miller Equipment</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/heavyquip">
            <img
              src="/images/galleries/gallery/Gallery_14.jpeg"
              alt="Heavy Quip Inc."
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Heavy Quip Inc.</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/altorfer">
            <img
              src="/images/galleries/gallery/Gallery_15.jpeg"
              alt="Altorfer Cat"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Altorfer Cat</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/thompsonmachinery">
            <img
              src="/images/galleries/gallery/Gallery_16.jpeg"
              alt="Thompson Machinery"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Thompson Machinery</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/thompsontractor">
            <img
              src="/images/galleries/gallery/Gallery_27.jpg"
              alt="Thompson Tractor"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Thompson Tractor</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/yellowstonevalley">
            <img
              src="/images/galleries/gallery/Gallery_17.jpeg"
              alt="Yellowstone Valley Parts"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Yellowstone Valley Parts</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/wellertractor">
            <img
              src="/images/galleries/gallery/Gallery_18.jpeg"
              alt="Weller Tractor Salvage"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Weller Tractor Salvage</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/boydandsons">
            <img
              src="/images/galleries/gallery/Gallery_19.jpeg"
              alt="Boyd and Sons"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Boyd and Sons</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/ironking">
            <img
              src="/images/galleries/gallery/Gallery_20.jpeg"
              alt="Iron King"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Iron King</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/billmiller">
            <img
              src="/images/galleries/gallery/Gallery_21.jpeg"
              alt="Bill Miller Equipment"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Bill Miller Equipment</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/heavyquip">
            <img
              src="/images/galleries/gallery/Gallery_22.jpeg"
              alt="Heavy Quip Inc."
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Heavy Quip Inc.</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="/galleries/altorfer">
            <img
              src="/images/galleries/gallery/Gallery_23.jpeg"
              alt="Altorfer Cat"
              className="d-block w-100"
            />
            <Carousel.Caption className="carouselGallery-caption">
              <h3>Altorfer Cat</h3>
            </Carousel.Caption>
          </a>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default GalleriesCarousel;
