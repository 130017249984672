import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Masthead from '../../components/Masthead';
import ContactUs from '../../components/ContactUs';
import GalleriesCarousel from '../../components/GalleriesCarousel';
import PartSellerIntro from '../../components/PartSeller/PartSellerIntro';

function PartSellerPage() {
  return (
    <div>
      <Masthead
        backgroundImage="/images/headers/Part_Seller_Header.jpeg"
        headerText="Parts Seller"
        headerSubtext=""
      />
      <Container className="py-3">
        <Row>
          <Col>
            <PartSellerIntro />
          </Col>
          <Col xs={12} lg={5} xl={4}>
            <ContactUs />
            <GalleriesCarousel />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PartSellerPage;
