import React from 'react';
import { Carousel } from 'react-bootstrap';

function BillMillerCarousel() {
  return (
    <Carousel>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/headers/Bill_Header.jpeg"
            alt="BillGallery1"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_2.jpeg"
            alt="BillGallery2"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_3.jpeg"
            alt="BillGallery3"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_4.jpeg"
            alt="BillGallery4"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_5.jpeg"
            alt="BillGallery5"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_6.jpeg"
            alt="BillGallery6"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_7.jpeg"
            alt="BillGallery7"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_8.jpeg"
            alt="BillGallery8"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_9.jpeg"
            alt="BillGallery9"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_10.jpeg"
            alt="BillGallery10"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_11.jpeg"
            alt="BillGallery11"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_12.jpeg"
            alt="BillGallery12"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_13.jpeg"
            alt="BillGallery13"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_14.jpeg"
            alt="BillGallery14"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_15.jpeg"
            alt="BillGallery15"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_16.jpeg"
            alt="BillGallery16"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_17.jpeg"
            alt="BillGallery17"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_18.jpeg"
            alt="BillGallery18"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_19.jpeg"
            alt="BillGallery19"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_20.jpeg"
            alt="BillGallery20"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_21.jpeg"
            alt="BillGallery21"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_22.jpeg"
            alt="BillGallery22"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_23.jpeg"
            alt="BillGallery23"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_24.jpeg"
            alt="BillGallery24"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_25.jpeg"
            alt="BillGallery25"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_26.jpeg"
            alt="BillGallery26"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_27.jpeg"
            alt="BillGallery27"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_28.jpeg"
            alt="BillGallery28"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_29.jpeg"
            alt="BillGallery29"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_30.jpeg"
            alt="BillGallery30"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_31.jpeg"
            alt="BillGallery31"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_32.jpeg"
            alt="BillGallery32"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_33.jpeg"
            alt="BillGallery33"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.bmillerequipmentsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/billmiller/Bill_Gallery_34.jpeg"
            alt="BillGallery34"
          />
        </a>
      </Carousel.Item>
    </Carousel>
  );
}

export default BillMillerCarousel;
