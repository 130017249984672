import React from 'react';
import BrowsePart from '../../components/BrowseParts';

function BrowsePartPage() {
  return (
    <div>
      <BrowsePart />
    </div>
  );
}

export default BrowsePartPage;
