import React from 'react';
import { Container } from 'react-bootstrap';
import ListAttachment from '../../components/ForSale/ListAttachment';

function OtherItemsForSalePage() {
  const category = 106;

  return (
    <div>
      <Container className="py-3">
        <h1 className="pb-3">Other Items For Sale</h1>
        <ListAttachment category={category} />
      </Container>
    </div>
  );
}

export default OtherItemsForSalePage;
