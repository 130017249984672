import React from 'react';
import { Carousel } from 'react-bootstrap';

function WellerCarousel() {
  return (
    <Carousel>
      <Carousel.Item>
        <a
          href="https://wellerparts.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/weller/Weller_Carousel_1.jpeg"
            alt="WellerGallery1"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://wellerparts.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/weller/Weller_Carousel_2.jpeg"
            alt="WellerGallery2"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://wellerparts.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/weller/Weller_Carousel_3.jpeg"
            alt="WellerGallery3"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://wellerparts.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/weller/Weller_Carousel_4.jpeg"
            alt="WellerGallery4"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://wellerparts.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/weller/Weller_Carousel_5.jpeg"
            alt="WellerGallery5"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://wellerparts.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/weller/Weller_Carousel_6.jpeg"
            alt="WellerGallery6"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://wellerparts.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/weller/Weller_Carousel_7.jpeg"
            alt="WellerGallery7"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://wellerparts.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/weller/Weller_Carousel_8.jpeg"
            alt="WellerGallery8"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://wellerparts.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/weller/Weller_Carousel_9.jpeg"
            alt="WellerGallery9"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://wellerparts.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/weller/Weller_Carousel_10.jpeg"
            alt="WellerGallery10"
          />
        </a>
      </Carousel.Item>
    </Carousel>
  );
}

export default WellerCarousel;
