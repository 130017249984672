import React from 'react';
import { Carousel } from 'react-bootstrap';

function IronKingCarousel() {
  return (
    <Carousel>
      <Carousel.Item>
        <a
          href="https://www.ironkingparts.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/headers/Iron_King_Header.jpeg"
            alt="IronKingGallery1"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.ironkingparts.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/ironking/Iron_King_Gallery_2.jpeg"
            alt="IronKingGallery2"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.ironkingparts.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/ironking/Iron_King_Gallery_3.jpeg"
            alt="IronKingGallery3"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.ironkingparts.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/ironking/Iron_King_Gallery_4.jpeg"
            alt="IronKingGallery4"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.ironkingparts.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/ironking/Iron_King_Gallery_5.png"
            alt="IronKingGallery5"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.ironkingparts.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/ironking/Iron_King_Gallery_6.jpeg"
            alt="IronKingGallery6"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.ironkingparts.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/ironking/Iron_King_Gallery_7.jpeg"
            alt="IronKingGallery7"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.ironkingparts.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/ironking/Iron_King_Gallery_8.jpeg"
            alt="IronKingGallery8"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.ironkingparts.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/ironking/Iron_King_Gallery_9.jpeg"
            alt="IronKingGallery9"
          />
        </a>
      </Carousel.Item>
    </Carousel>
  );
}

export default IronKingCarousel;
