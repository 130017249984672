import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Masthead from '../../components/Masthead';
import ContactUs from '../../components/ContactUs';
import GalleriesCarousel from '../../components/GalleriesCarousel';
import TestimonialAltorferCat from '../../components/Testimonials/TestimonialAltorferCat';
import TestimonialPortlandTractor from '../../components/Testimonials/TestimonialPortlandTractor';
import TestimonialThompsonMachinery from '../../components/Testimonials/TestimonialThompsonMachinery';
import TestimonialThompsonTractor from '../../components/Testimonials/TestimonialThompsonTractor';

function TestimonialsPage() {
  return (
    <div>
      <Masthead
        backgroundImage="/images/headers/Testimonials_Header.jpeg"
        headerText="Testimonials"
        headerSubtext=""
      />
      <Container className="py-3">
        <Row className="pb-4">
          <Col>
            <h1 className="mb-4">What our Customers say?</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <TestimonialAltorferCat />
            <TestimonialThompsonTractor />
            <TestimonialThompsonMachinery />
            <TestimonialPortlandTractor />
          </Col>
          <Col xs={12} lg={5} xl={4}>
            <ContactUs />
            <GalleriesCarousel />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TestimonialsPage;
