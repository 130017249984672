import React from 'react';
import { Carousel } from 'react-bootstrap';

function AltorferCarousel() {
  return (
    <Carousel>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_1.jpeg"
            alt="AltorferGallery1"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_2.jpeg"
            alt="AltorferGallery2"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_3.jpeg"
            alt="AltorferGallery3"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_4.jpeg"
            alt="AltorferGallery4"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_5.jpeg"
            alt="AltorferGallery5"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_6.jpeg"
            alt="AltorferGallery6"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_7.jpeg"
            alt="AltorferGallery7"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_8.jpeg"
            alt="AltorferGallery8"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_9.jpeg"
            alt="AltorferGallery9"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_10.jpeg"
            alt="AltorferGallery10"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_11.jpeg"
            alt="AltorferGallery11"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_12.jpeg"
            alt="AltorferGallery12"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_13.jpeg"
            alt="AltorferGallery13"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_14.jpeg"
            alt="AltorferGallery14"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_15.jpeg"
            alt="AltorferGallery15"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_16.jpeg"
            alt="AltorferGallery16"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_17.jpeg"
            alt="AltorferGallery17"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_18.jpeg"
            alt="AltorferGallery18"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_19.jpeg"
            alt="AltorferGallery19"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_20.jpeg"
            alt="AltorferGallery20"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_21.jpeg"
            alt="AltorferGallery21"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_22.jpeg"
            alt="AltorferGallery22"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_23.jpeg"
            alt="AltorferGallery23"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_24.jpeg"
            alt="AltorferGallery24"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_25.jpeg"
            alt="AltorferGallery25"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_26.jpeg"
            alt="AltorferGallery26"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.altorfer.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/altorfer/Altorfer_Gallery_27.jpeg"
            alt="AltorferGallery27"
          />
        </a>
      </Carousel.Item>
    </Carousel>
  );
}

export default AltorferCarousel;
