import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  Alert,
  Button
} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function UserProfilePage() {
  const [userDetails, setUserDetails] = useState({});
  const { userInfo, accessToken } = useAuth(); // Assuming useAuth now provides userInfo and accessToken
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const location = useLocation();
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState(
    location.state?.message || ''
  );
  const [infoMessage, setInfoMessage] = useState(
    location.state?.message || '' // New state for informational messages
  );

  const [resendLoading, setResendLoading] = useState(false);
  const [resendSuccessMessage, setResendSuccessMessage] = useState('');
  const [resendError, setResendError] = useState('');

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/user/${userInfo.userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          setUserDetails(data.data.user); // Assuming the backend sends the user details in this format
        } else {
          // Handle errors or invalid responses
          // eslint-disable-next-line no-console
          console.error('Failed to fetch user details');
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching user details:', error);
      }
    };

    if (userInfo?.userId) {
      fetchUserDetails();
    }
  }, [userInfo?.userId, accessToken, baseUrl]); // Dependency array ensures useEffect runs when userId or accessToken changes

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => setSuccessMessage(''), 5000);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [successMessage]);

  useEffect(() => {
    if (infoMessage) {
      const timer = setTimeout(() => setInfoMessage(''), 5000);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [infoMessage]);

  useEffect(() => {
    if (resendSuccessMessage) {
      const timer = setTimeout(() => setResendSuccessMessage(''), 5000);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [resendSuccessMessage]);

  useEffect(() => {
    if (resendError) {
      const timer = setTimeout(() => setResendError(''), 5000);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [resendError]);

  const handleCreateCompany = () => {
    navigate('/create-company');
  };

  const resendVerificationEmail = async () => {
    setResendLoading(true);
    setResendSuccessMessage('');
    setResendError('');
    try {
      const response = await fetch(
        `${baseUrl}/api/user/resend-verification-email`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      const data = await response.json();

      if (response.ok) {
        setResendSuccessMessage(
          'Verification email sent. Please check your inbox.'
        );
      } else {
        setResendError(data.message || 'Failed to resend verification email.');
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error resending verification email:', err);
      setResendError(
        'An error occurred while resending the verification email.'
      );
    } finally {
      setResendLoading(false);
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} className="mt-2 mb-2">
          <h1 className="text-center">Account Information</h1>
          {/* {infoMessage && <Alert variant="info">{infoMessage}</Alert>}{' '} */}
          {userDetails.companyId === null && (
            <Alert variant="warning" className="text-center">
              You must add company information to activate your account.
            </Alert>
          )}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          {/* Display email verification alert */}
          {!userDetails.email_verified && (
            <Alert variant="warning">
              Your email is not verified. Please verify your email to access all
              features.
              <div className="mt-2">
                <Button
                  variant="primary"
                  onClick={resendVerificationEmail}
                  disabled={resendLoading}
                >
                  {resendLoading ? 'Sending...' : 'Resend Verification Email'}
                </Button>
              </div>
            </Alert>
          )}
          {resendSuccessMessage && (
            <Alert variant="success">{resendSuccessMessage}</Alert>
          )}
          {resendError && <Alert variant="danger">{resendError}</Alert>}
        </Col>

        {/* Section 1: Account Information Description */}
        <Row className="d-flex">
          <Col xs={12} lg={6} className="mb-4 d-flex">
            <Card className="p-2 flex-fill">
              <Card.Body>
                <h2 className="text-center mb-3">About Your Account</h2>
                <p>
                  Thank you for creating an account with the NetCom Part Matcher
                  service. With this service you can search for parts from some
                  of the most active heavy construction equipment parts dealers
                  in North America.
                </p>
                <p>
                  IMPORTANT: If you are a member of the NetCom Parts Network you
                  are on the wrong website for that service. If you need help
                  getting logged into that service please call us at (800)
                  775-2962 or email us at{' '}
                  <a href="mailto:netcomservicesllc@yahoo.com">
                    netcomservicesllc@yahoo.com
                  </a>
                  .
                </p>
              </Card.Body>
            </Card>
          </Col>

          {/* Section 2: Contact Us for Help */}
          <Col xs={12} lg={6} className="mb-4 d-flex">
            <Card className="p-2 flex-fill">
              <Card.Body>
                <h2 className="text-center mb-3">Need Help?</h2>
                <p>
                  If you need to make changes to your account or require
                  assistance, please call us at (800)775-2962 or email us at{' '}
                  <a href="mailto:netcomservicesllc@yahoo.com">
                    netcomservicesllc@yahoo.com
                  </a>
                  .
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* Section 3: User Contact Information */}
        <Col xs={12} md={6} className="mb-4">
          <Card className="p-4">
            <Card.Body>
              <h3 className="section-title text-center mb-3">
                User Contact Information
              </h3>
              <ListGroup variant="flush">
                <ListGroup.Item className="d-flex justify-content-between">
                  <span>Email:</span>
                  <span className="ms-auto">{userDetails.email || ''}</span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between">
                  <span>First Name:</span>
                  <span className="ms-auto">{userDetails.firstName || ''}</span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between">
                  <span>Last Name:</span>
                  <span className="ms-auto">{userDetails.lastName || ''}</span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between">
                  <span>Office Phone Number:</span>
                  <span className="ms-auto">
                    {userDetails.phoneOffice || ''}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between">
                  <span>Mobile Phone Number:</span>
                  <span className="ms-auto">
                    {userDetails.phoneMobile || ''}
                  </span>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>

        {/* Section 4: Company Contact Information */}
        <Col xs={12} md={6} className="mb-4">
          <Card className="p-4">
            <Card.Body>
              <h3 className="section-title text-center mb-3">
                Company Contact Information
              </h3>
              {userDetails.Company ? (
                <ListGroup variant="flush">
                  <ListGroup.Item className="d-flex justify-content-between">
                    <span>Company Name:</span>
                    <span className="ms-auto">
                      {userDetails.Company.name || ''}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between">
                    <span>Company Phone:</span>
                    <span className="ms-auto">
                      {userDetails.Company.phoneMain || ''}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between">
                    <span>Secondary Company Phone:</span>
                    <span className="ms-auto">
                      {userDetails.Company.phoneSecondary || 'Not Provided'}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between">
                    <span>Fax:</span>
                    <span className="ms-auto">
                      {userDetails.Company.fax || 'Not Provided'}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between">
                    <span>Company Address:</span>
                    <span className="ms-auto">
                      {userDetails.Company.shipAddress1 && (
                        <>
                          {userDetails.Company.shipAddress1}
                          <br />
                        </>
                      )}
                      {userDetails.Company.shipAddress2 && (
                        <>
                          {userDetails.Company.shipAddress2}
                          <br />
                        </>
                      )}
                      {userDetails.Company.shipCity && (
                        <>{userDetails.Company.shipCity}, </>
                      )}
                      {userDetails.Company.shipState && (
                        <>{userDetails.Company.shipState}, </>
                      )}
                      {userDetails.Company.shipZip && (
                        <>{userDetails.Company.shipZip}, </>
                      )}
                      {userDetails.Company.shipCountry &&
                        userDetails.Company.shipCountry}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between">
                    <span>Billing Address:</span>
                    <span className="ms-auto">
                      {userDetails.Company.billAddress1 && (
                        <>
                          {userDetails.Company.billAddress1}
                          <br />
                        </>
                      )}
                      {userDetails.Company.billAddress2 && (
                        <>
                          {userDetails.Company.billAddress2}
                          <br />
                        </>
                      )}
                      {userDetails.Company.billCity && (
                        <>{userDetails.Company.billCity}, </>
                      )}
                      {userDetails.Company.billState && (
                        <>{userDetails.Company.billState}, </>
                      )}
                      {userDetails.Company.billZip && (
                        <>{userDetails.Company.billZip}, </>
                      )}
                      {userDetails.Company.billCountry &&
                        userDetails.Company.billCountry}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between">
                    <span>Company Email:</span>
                    <span className="ms-auto">
                      {userDetails.Company.email1 || ''}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between">
                    <span>Secondary Email:</span>
                    <span className="ms-auto">
                      {userDetails.Company.email2 || 'Not Provided'}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between">
                    <span>Tertiary Email:</span>
                    <span className="ms-auto">
                      {userDetails.Company.email3 || 'Not Provided'}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between">
                    <span>Parts Manager:</span>
                    <span className="ms-auto">
                      {userDetails.Company.partsManager || ''}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between">
                    <span>Contacts:</span>
                    <span className="ms-auto">
                      {userDetails.Company.contactList || 'Not Provided'}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between">
                    <span>Company Website:</span>
                    <span className="ms-auto">
                      {userDetails.Company.website || 'Not Provided'}
                    </span>
                  </ListGroup.Item>
                </ListGroup>
              ) : (
                <Alert variant="warning" className="text-center">
                  You must add company information to activate your account.
                </Alert>
              )}
              {!userDetails.Company && (
                <div className="text-center mt-3">
                  <Button variant="primary" onClick={handleCreateCompany}>
                    Add Company Information
                  </Button>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default UserProfilePage;
