import React from 'react';
import { Carousel } from 'react-bootstrap';

function ThompsonTractorCarousel() {
  return (
    <Carousel>
      <Carousel.Item>
        <a
          href="https://thompsontractor.com/used-equipment/machinery/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/thompsontractor/thompsontractor01.jpg"
            alt="ThompsonTractorGallery1"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://thompsontractor.com/used-equipment/machinery/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/thompsontractor/thompsontractor02.jpg"
            alt="ThompsonTractorGallery2"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://thompsontractor.com/used-equipment/machinery/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/thompsontractor/thompsontractor03.jpg"
            alt="ThompsonTractorGallery3"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://thompsontractor.com/used-equipment/machinery/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/thompsontractor/thompsontractor04.jpg"
            alt="ThompsonTractorGallery4"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://thompsontractor.com/used-equipment/machinery/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/thompsontractor/thompsontractor05.jpg"
            alt="ThompsonTractorGallery5"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://thompsontractor.com/used-equipment/machinery/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/thompsontractor/thompsontractor06.jpg"
            alt="ThompsonTractorGallery6"
          />
        </a>
      </Carousel.Item>
    </Carousel>
  );
}

export default ThompsonTractorCarousel;
