import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function EmailVerifiedRoute({ children }) {
  const { userInfo } = useAuth();
  const location = useLocation();

  if (!userInfo.email_verified) {
    // User is not verified, redirect to profile or a specific verification page
    return (
      <Navigate
        to="/profile"
        state={{ from: location, showVerificationMessage: true }}
        replace
      />
    );
  }

  // User is verified, render the children components
  return children;
}

// Define propTypes for the component
EmailVerifiedRoute.propTypes = {
  children: PropTypes.node.isRequired // `children` should be a React node and is required
};

export default EmailVerifiedRoute;
