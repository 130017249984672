import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function BackgroundCard({ url, imgSrc, altText, title, text }) {
  // Inline styles
  const cardStyle = {
    border: 'none',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.3s, box-shadow 0.3s',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    maxHeight: '250px'
  };

  const linkStyle = {
    display: 'block',
    height: '100%',
    color: 'inherit',
    textDecoration: 'none'
  };

  const imgStyle = {
    objectFit: 'cover',
    maxHeight: '250px',
    height: '100%',
    width: '100%'
  };

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)', // Semi-transparent overlay
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
    padding: '20px',
    transition: 'background-color 0.3s'
  };

  const titleStyle = {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '0.5rem'
  };

  const textStyle = {
    fontSize: '1rem'
  };

  // Hover effect styles using React state
  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const hoverStyle = isHovered
    ? {
        transform: 'scale(1.02)',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)'
      }
    : {};

  const overlayHoverStyle = isHovered
    ? {
        backgroundColor: 'rgba(0, 0, 0, 0.6)'
      }
    : {};

  return (
    <Card
      style={{ ...cardStyle, ...hoverStyle }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Link to={url} style={linkStyle}>
        <Card.Img src={imgSrc} alt={altText} style={imgStyle} />
        <div style={{ ...overlayStyle, ...overlayHoverStyle }}>
          <Card.Title style={titleStyle}>{title}</Card.Title>
          <Card.Text style={textStyle}>{text}</Card.Text>
        </div>
      </Link>
    </Card>
  );
}

BackgroundCard.propTypes = {
  url: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default BackgroundCard;
