import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import manufListHook from '@netcom-network-v2/shared/src/hooks/manufListHook';
import { getRandomCompanyName } from '../utils/RandomCompanyName';
import { guestFlexBasis } from '../utils/getFlexBasis';
import calculateButtonIndices from '../utils/calculateButtonIndices'; // Import the helper function
import useGuestPartSearch from '../hooks/useGuestPartSearch';
import fetchIpAddress from '../utils/ipService'; // Import the IP service

function GuestPartSearch() {
  const navigate = useNavigate();
  const { partNumber: routePartNumber } = useParams();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const [partNumber, setPartNumber] = useState('');
  const [condition, setCondition] = useState('All'); // State for condition
  const [manufacturer, setManufacturer] = useState('All'); // State for condition
  const {
    searchResults = [],
    error,
    searchParts
  } = useGuestPartSearch(baseUrl, navigate);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [ipAddress, setIpAddress] = useState('');
  const { manufList } = manufListHook(baseUrl);
  const [focusedField, setFocusedField] = useState(null); // Track which field is focused
  const lastSearchedParamsRef = useRef('');

  const openCreateAccount = () => {
    navigate('/register');
  };

  useEffect(() => {
    const getIp = async () => {
      if (!ipAddress) {
        const ip = await fetchIpAddress();
        setIpAddress(ip);
      }
    };

    getIp();
  }, [ipAddress]);

  useEffect(() => {
    const currentSearch = `${routePartNumber}|${condition}|${manufacturer}`;
    if (lastSearchedParamsRef.current === currentSearch) return;

    if (!ipAddress) return;
    if (routePartNumber) {
      setPartNumber(routePartNumber);
      searchParts(routePartNumber, condition, manufacturer, ipAddress);

      lastSearchedParamsRef.current = currentSearch;
    }
  }, [routePartNumber, searchParts, ipAddress]);

  const handleSearch = async () => {
    if (!partNumber) {
      navigate('/partmatcher/guestpartsearch');
      return;
    }
    navigate(`/partmatcher/guestpartsearch/${partNumber}`, { replace: true });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleInputChange = e => {
    const formattedInput = e.target.value
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, '');
    setPartNumber(formattedInput);
  };
  const handleConditionChange = e => {
    setCondition(e.target.value);
  };

  const handleManufacturerChange = e => {
    setManufacturer(e.target.value);
  };

  const buttonIndices = useMemo(
    () => calculateButtonIndices(searchResults.length),
    [searchResults.length]
  );

  // Added this so when you resize the page it doesn't generate a new company name.
  const augmentedSearchResults = useMemo(
    () =>
      searchResults.map(part => ({
        ...part,
        randomCompanyName: getRandomCompanyName()
      })),
    [searchResults]
  );

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      {focusedField && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 10 // Ensure overlay is just below the input fields
          }}
          onClick={() => setFocusedField(null)} // Click outside any field to unfocus
          onKeyDown={e => {
            if (e.key === 'Escape') {
              setFocusedField(null); // Handle escape key to close overlay
            }
          }}
          role="button" // Assign role to indicate the div is interactive
          tabIndex={0} // Make it focusable
          aria-label="Close overlay" // Provide an accessible name
        />
      )}
      <div
        style={{
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          margin: '20px',
          padding: '20px',
          borderRadius: '8px',
          backgroundColor: 'lightBlue'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            justifyContent: 'center',
            marginBottom: '10px',
            marginTop: '10px',
            width: '100%'
          }}
        >
          <input
            id="partNumber"
            type="text"
            value={partNumber}
            onChange={handleInputChange}
            placeholder="Enter Part Number"
            onFocus={() => setFocusedField('partNumber')}
            onBlur={() => setFocusedField(null)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleSearch();
                e.target.blur();
              }
            }}
            style={{
              padding: '10px',
              fontSize: '16px',
              borderRadius: '4px',
              border: '2px solid #ccc',
              order: 1,
              minWidth: '150px',
              flex: `0 1 ${guestFlexBasis(windowWidth, 0)}`,
              height: '50px',
              zIndex: focusedField ? 11 : 1
            }}
          />

          <select
            value={manufacturer}
            onChange={handleManufacturerChange}
            style={{
              padding: '10px',
              fontSize: '16px',
              borderRadius: '4px',
              border: '2px solid #ccc',
              order: 3,
              minWidth: '150px',
              flex: `0 1 ${guestFlexBasis(windowWidth, 1)}`,
              height: '50px',
              zIndex: focusedField ? 11 : 1
            }}
          >
            <option value="All">All Manufacturers</option>
            {manufList.map(manuf => (
              <option key={manuf.id} value={manuf.abbrname}>
                {manuf.abbrname}
              </option>
            ))}
          </select>

          <select
            value={condition}
            onChange={handleConditionChange}
            style={{
              padding: '10px',
              fontSize: '16px',
              borderRadius: '4px',
              border: '2px solid #ccc',
              order: 4,
              minWidth: '150px',
              flex: `0 1 ${guestFlexBasis(windowWidth, 2)}`,
              height: '50px',
              zIndex: focusedField ? 11 : 1
            }}
          >
            <option value="All">All Conditions</option>
            <option value="AFTERMARKET">AFTERMARKET</option>
            <option value="CORE">CORE</option>
            <option value="NEW">NEW</option>
            <option value="REBUILT">REBUILT</option>
            <option value="USED">USED</option>
          </select>

          <button
            type="button"
            onClick={handleSearch}
            style={{
              padding: '10px 20px',
              fontSize: '16px',
              borderRadius: '4px',
              backgroundColor: '#007BFF',
              color: 'white',
              border: 'none',
              cursor: 'pointer',
              height: '48px',
              maxWidth: '150px',
              flex: `1 1 ${guestFlexBasis(windowWidth, 3)}`,
              zIndex: focusedField ? 11 : 1,
              order: windowWidth > 1025 ? 5 : 8 // Adjust order based on windowWidth
            }}
          >
            Search
          </button>
        </div>
      </div>

      {/* eslint-disable no-nested-ternary */}
      {error ? (
        <p style={{ marginLeft: '40px', fontSize: '20px', color: 'red' }}>
          {error}
        </p>
      ) : searchResults?.length > 0 && routePartNumber ? (
        <div>
          <Table className="table-bordered table-striped">
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Part Number</th>
                <th>Description</th>
                <th>Condition</th>
                <th style={{ width: '9%' }}>Price</th>
                <th style={{ width: '9%' }}>Pictures</th>
                <th style={{ width: '12%' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {augmentedSearchResults.map((part, index) => (
                <tr key={part.id}>
                  <td>
                    {buttonIndices.has(index) ? (
                      <div
                        style={{
                          position: 'relative',
                          width: '100%',
                          overflow: 'hidden'
                        }}
                      >
                        <button
                          onClick={openCreateAccount}
                          type="button"
                          style={{
                            all: 'unset',
                            cursor: 'pointer',
                            display: 'block',
                            width: '100%',
                            textAlign: 'center',
                            padding: '5px 0',
                            fontSize: '16px',
                            color: '#007BFF',
                            textDecoration: 'underline',
                            position: 'relative',
                            zIndex: 2
                          }}
                        >
                          Create Account to View
                        </button>
                      </div>
                    ) : (
                      <div
                        style={{
                          filter: 'blur(4px)',
                          color: '#000', // Text color
                          cursor: 'pointer',
                          textAlign: 'left',
                          padding: '5px 0',
                          fontSize: '16px'
                        }}
                      >
                        <button
                          onClick={openCreateAccount}
                          type="button"
                          style={{
                            all: 'unset',
                            cursor: 'pointer',
                            display: 'block',
                            width: '100%'
                          }}
                        >
                          {part.randomCompanyName}
                        </button>
                      </div>
                    )}
                  </td>
                  <td>{part.partNumber}</td>
                  <td>{part.description}</td>
                  <td>{part.condlong}</td>

                  <td
                    colSpan="3"
                    style={{ verticalAlign: 'middle', textAlign: 'center' }}
                  >
                    {buttonIndices.has(index) ? (
                      <div
                        style={{
                          position: 'relative',
                          width: '100%',
                          overflow: 'hidden'
                        }}
                      >
                        <button
                          onClick={openCreateAccount}
                          type="button"
                          style={{
                            all: 'unset',
                            cursor: 'pointer',
                            display: 'block',
                            width: '100%',
                            textAlign: 'center',
                            padding: '5px 0',
                            fontSize: '16px',
                            color: '#007BFF',
                            textDecoration: 'underline',
                            position: 'relative',
                            zIndex: 2 // Position above the blurred background
                          }}
                        >
                          Create Account to View
                        </button>
                      </div>
                    ) : (
                      <div
                        style={{
                          filter: 'blur(4px)',
                          color: '#000', // Text color
                          cursor: 'pointer',
                          textAlign: 'left',
                          padding: '5px 0',
                          fontSize: '16px'
                        }}
                      >
                        <button
                          onClick={openCreateAccount}
                          type="button"
                          style={{
                            all: 'unset',
                            cursor: 'pointer',
                            display: 'block',
                            width: '100%'
                          }}
                        >
                          <img
                            src="/images/priceimagemessage.png"
                            alt="Thumbnail for part images"
                            style={{
                              width: '100%',
                              height: '35px',
                              display: 'flex'
                            }}
                            onError={e => {
                              e.target.src = '/images/priceimagemessage.png';
                            }}
                          />{' '}
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div
          style={{
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            margin: '20px',
            padding: '20px',
            borderRadius: '8px',
            backgroundColor: 'white'
          }}
        >
          <div style={{ marginBottom: '20px' }}>
            <h2 style={{ color: '#333' }}>NetCom Part Finding Tool</h2>
          </div>
          <div>
            <p style={{ color: '#666' }}>
              Find essential parts from the leading heavy construction dealers
              across North America with our dedicated NetCom Part Finding Tool.
              This powerful resource allows you to search and locate a wide
              variety of parts conveniently.
            </p>
            <h3 style={{ color: '#333', marginTop: '20px' }}>
              For NetCom Network Inquiries:
            </h3>
            <p style={{ color: '#666' }}>
              If you&rsquo;re interested in exploring NetCom Network options,
              please don&rsquo;t hesitate to contact us. Email us at{' '}
              <a href="thepartfinder@yahoo.com" style={{ color: '#007BFF' }}>
                thepartfinder@yahoo.com
              </a>{' '}
              for detailed information and personalized assistance.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default GuestPartSearch;
