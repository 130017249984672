import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function PartsNetworkIntro() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Heavy Construction Equipment Parts Network</h1>
          <p>
            Since 1992, the industrys most active dealers have used NetCom
            Services parts network to locate and sell heavy construction parts
            and attachments.
          </p>
          <ul>
            <li>
              Private parts network for established heavy construction equipment
              dealers.
            </li>
            <li>
              Easy to use parts locating network designed specifically for the
              heavy equipment construction industry
            </li>
            <li>
              25 years of experience running a heavy construction parts network.
            </li>
            <li>Secure private database with 15+ years of searchable data.</li>
            <li>5+ million parts updated monthly.</li>
            <li>1+ million part matches per year.</li>
            <li>
              Searchable inventory from a wide variety of manufacturers
              including: Caterpillar®, Komatsu® , John Deere® , IHC® ,
              Case® and many more.
            </li>
            <li>
              Membership includes access to our Inventory Management and Part
              Locating Statistics applications.
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default PartsNetworkIntro;
