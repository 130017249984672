import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function TestimonialPortlandTractor() {
  return (
    <Container className="testimonials_container mb-3 pb-3 pt-3">
      <Row>
        <Col>
          <img
            src="/images/testimonials/PortlandTestimonial.jpeg"
            alt="Portland Tractor Testimonial"
            className="testimonials_image float-start pe-3"
          />
          <p>
            &quot;I have had the privilege to use the NetCom Services Parts
            Network for over 25 years now. I can attest that their network is a
            top-notch way to increase any used equipment parts dealers sales
            significantly and reach an extensive network of seasoned qualified
            dealers inventories as well. Currently working at a non-Cat dealer
            entity but previously at Cat dealerships, I&apos;ve found they treat
            everybody like family, big or small. I have the blessing of working
            with one of the larger used parts inventories in North America but
            find myself locating and reselling other dealer&apos;s products
            daily as you never have every item your customers want. Still, it
            makes you the customers first call when you can find what they need
            in seconds. Affordably priced and easy to use, NetCom Services is a
            winner!&quot; - Tom Arndt, Sales, Portland Tractor Inc.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default TestimonialPortlandTractor;
