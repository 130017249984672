import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import refreshAccessToken from '../utils/refreshToken';

const useCompanyRegistration = apiBaseUrl => {
  const [formData, setFormData] = useState({
    name: '',
    phoneMain: '',
    phoneSecondary: '',
    fax: '',
    shipAddress1: '',
    shipAddress2: '',
    shipCity: '',
    shipState: '',
    shipZip: '',
    shipCountry: '',
    billAddress1: '',
    billAddress2: '',
    billCity: '',
    billState: '',
    billZip: '',
    billCountry: '',
    email1: '',
    email2: '',
    email3: '',
    partsManager: '',
    contactList: '',
    website: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const { accessToken, refreshToken, updateTokens, logout } = useAuth();
  const navigate = useNavigate();

  // Function to validate input fields
  const validate = () => {
    const tempErrors = {};
    if (!formData.name) {
      tempErrors.name = 'Company Name is required';
    }
    if (!formData.phoneMain) {
      tempErrors.phoneMain = 'Main Phone is required';
    }
    if (!formData.shipAddress1) {
      tempErrors.shipAddress1 = 'Shipping Address 1 is required';
    }
    if (!formData.shipCity) {
      tempErrors.shipCity = 'Shipping City is required';
    }
    if (!formData.shipState) {
      tempErrors.shipState = 'Shipping State is required';
    }
    if (!formData.shipZip) {
      tempErrors.shipZip = 'Shipping Zip Code is required';
    }
    if (!formData.shipCountry) {
      tempErrors.shipCountry = 'Shipping Country is required';
    }
    if (!formData.email1) {
      tempErrors.email1 = 'Primary Email is required';
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Function to handle form input changes
  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  // Function to submit the registration form
  const handleSubmit = async e => {
    e.preventDefault();
    if (!validate()) return; // Exit if validation fails

    setIsSubmitting(true);
    setErrors({}); // Clear existing errors
    setSuccessMessage(''); // Clear existing success message

    const trimmedFormData = {
      ...formData,
      email1: formData.email1.trim(),
      email2: formData.email2.trim(),
      email3: formData.email3.trim()
    };

    try {
      const response = await fetch(`${apiBaseUrl}/api/company`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(trimmedFormData)
      });

      const data = await response.json();

      if (response.ok) {
        setSuccessMessage('Company added successfully.');
        // Optionally reset form here if needed

        setFormData({
          name: '',
          phoneMain: '',
          phoneSecondary: '',
          fax: '',
          shipAddress1: '',
          shipAddress2: '',
          shipCity: '',
          shipState: '',
          shipZip: '',
          shipCountry: '',
          billAddress1: '',
          billAddress2: '',
          billCity: '',
          billState: '',
          billZip: '',
          billCountry: '',
          email1: '',
          email2: '',
          email3: '',
          partsManager: '',
          contactList: '',
          website: ''
        });
        navigate('/profile', {
          state: { message: 'Company added successfully.' }
        });
        await refreshAccessToken(
          apiBaseUrl,
          refreshToken,
          updateTokens,
          logout,
          data.data.companyId
          // tested sending back a different companies Id here - put any number in place of companyId to see if it works, it shouldnt.
        );
      } else {
        // eslint-disable-next-line no-console
        console.error('Company registration error:', data.message);
        // Handle other types of errors (e.g., server error, not found, etc.)
        setErrors({
          form: data.errors
            ? data.errors.join(', ')
            : data.message || 'An error occurred while adding the company.'
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Company registration error:', error);
      setErrors({ form: 'An error occurred while adding the company.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    formData,
    handleChange,
    handleSubmit,
    errors,
    isSubmitting,
    successMessage
  };
};

export default useCompanyRegistration;
