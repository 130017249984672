import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PartsNetworkIntro from '../../components/PartsNetwork/PartsNetworkIntro';
import Masthead from '../../components/Masthead';
import ContactUs from '../../components/ContactUs';
import GalleriesCarousel from '../../components/GalleriesCarousel';
import TestimonialAltorferCat from '../../components/Testimonials/TestimonialAltorferCat';
import TestimonialThompsonTractor from '../../components/Testimonials/TestimonialThompsonTractor';
import TestimonialThompsonMachinery from '../../components/Testimonials/TestimonialThompsonMachinery';
import TestimonialPortlandTractor from '../../components/Testimonials/TestimonialPortlandTractor';
import PartsNetworkFeatures from '../../components/PartsNetwork/PartsNetworkFeatures';
import PartsNetworkInventoryManager from '../../components/PartsNetwork/PartsNetworkInventoryManager';
import PartsNetworkStatsManager from '../../components/PartsNetwork/PartsNetworkStatsManager';
import PartsNetworkQuickQuote from '../../components/PartsNetwork/PartsNetworkQuickQuote';

function PartsNetworkPage() {
  return (
    <div>
      <Masthead
        backgroundImage="/images/headers/Parts_Network_Header.jpeg"
        headerText="Parts Network"
        headerSubtext=""
      />
      <Container className="py-3">
        <Row>
          <Col xs={12} lg={7} xl={8}>
            <PartsNetworkIntro />
            <h2>Testimonials</h2>
            <TestimonialAltorferCat />
            <TestimonialThompsonTractor />
            <TestimonialThompsonMachinery />
            <TestimonialPortlandTractor />
            <PartsNetworkFeatures />
            <PartsNetworkInventoryManager />
            <PartsNetworkStatsManager />
            <PartsNetworkQuickQuote />
          </Col>
          <Col xs={12} lg={5} xl={4}>
            <ContactUs />
            <GalleriesCarousel />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PartsNetworkPage;
