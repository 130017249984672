import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function PartSellerIntro() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Part Seller</h1>
          <p>
            Sell your parts directly to the most active heavy construction
            equipment buyers in the industry.
          </p>
          <p>
            With PartSeller your parts will show up directly in the search
            results of the most active heavy construction equipment buyers in
            the industry. Since 1992, the industries most active dealers have
            used NetCom Services parts network to locate heavy construction
            parts. Our members look for tens of thousands of parts every month.
            And now for the first time we are broadening our service to allow
            more heavy equipment part suppliers the opportunity to make sales.
          </p>
          <h3 className="mb-3">How It Works</h3>
          <h4>1. Send us your heavy construction parts</h4>
          <p>
            Once we receive your file we will optimize it so that you have the
            highest chance of your parts being requested.
          </p>
          <h4>2. Get notified when someone matches your parts</h4>
          <p>
            Email notifications allow you to respond back immediately. Or you
            can have our application automatically quote your sales price. Your
            choice.
          </p>
          <h4>3. Make a sale</h4>
          <p>
            Communicate with the buyer through our application and make a sale.
          </p>
          <h3>Benefits</h3>
          <ul>
            <li>Sell to most active buyers in industry</li>
            <li>
              Low return rates - our customers know exactly what they are
              looking for
            </li>
            <li>Get paid quickly</li>
          </ul>
          <h3>Requirements</h3>
          <p>
            Sellers must have all of the parts they are listing at their
            company&apos;s own facility. No brokers or resellers are allowed
          </p>
          <h3>Learn more</h3>
          <p className="mb-5">
            Contact us at (800)775-2962 or email thepartseller@gmail.com to
            learn more.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default PartSellerIntro;
