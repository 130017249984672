import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import StatsManagerGrid from './StatsManagerGrid';

function PartsNetworkStatsManager() {
  return (
    <Container>
      <Row>
        <Col>
          <h2>Stats Manager</h2>
          <p>
            Parts Network membership includes access to the following
            statistical information:
          </p>
          <ul>
            <li>
              Ability to see which of your parts are searched for the most and
              which parts you search for most often on a monthly and yearly
              basis.
            </li>
            <li>Top companies matching your parts.</li>
            <li>Top companies your searches matched.</li>
            <li>
              Daily, monthly, and yearly statistics on how many part requests
              you send and receive.
            </li>
            <li>
              Activity monitor allows you to see how many messages each of your
              accounts send and respond to on a daily, weekly, monthly and
              yearly basis.
            </li>
          </ul>
          <StatsManagerGrid />
        </Col>
      </Row>
    </Container>
  );
}

export default PartsNetworkStatsManager;
