import React from 'react';
import { Carousel } from 'react-bootstrap';
import PropTypes from 'prop-types';

function TableCarousel({ part }) {
  // Helper function to render images
  const renderImages = () => {
    if (part.pics && part.pics.length > 1) {
      return (
        <Carousel
          indicators={false}
          controls
          interval={null}
          wrap={false}
          slide={false}
          style={{ width: '100px', height: '80px' }}
        >
          {part.pics.map(img => (
            <Carousel.Item key={`${img}`}>
              <img
                src={img}
                alt={`Thumbnail for part ${part.partnum}`}
                style={{
                  width: '100px',
                  height: '80px',
                  objectFit: 'cover' // Ensures the image covers the container without distortion
                }}
                onError={e => {
                  e.target.onerror = null; // Prevents infinite loop if fallback fails
                  e.target.src = '/images/image_not_available.png';
                }}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      );
    }
    if (part.pics && part.pics.length === 1) {
      return (
        <img
          src={part.pics[0]}
          alt={`Thumbnail for part ${part.partnum}`}
          style={{
            width: '100px',
            height: '80px',
            objectFit: 'cover'
          }}
          onError={e => {
            e.target.onerror = null;
            e.target.src = '/images/image_not_available.png';
          }}
        />
      );
    }
    return (
      <img
        src="/images/image_not_available.png"
        alt="not available"
        style={{
          width: '100px',
          height: '80px',
          objectFit: 'cover'
        }}
      />
    );
  };

  return <div>{renderImages()}</div>;
}

TableCarousel.propTypes = {
  part: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
};

export default TableCarousel;
