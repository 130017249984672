import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Modal, Button, Card, Alert, Form } from 'react-bootstrap';
import PartDetailsModal from './partDetailsModal';
import usePartSearchResult from '../hooks/usePartSearchResult';

function PartSearchResult() {
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { searchResults, partSearches, error, searchParts } =
    usePartSearchResult(baseUrl, navigate);

  useEffect(() => {
    searchParts();
  }, []);

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [currentPartImages, setCurrentPartImages] = useState([]);
  const [currentPart, setCurrentPart] = useState('');
  const [visibleCount, setVisibleCount] = useState(25); // State to manage visible items

  // state variables for messaging
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [sending, setSending] = useState(false);
  const [sendError, setSendError] = useState('');
  const [partMessages, setPartMessages] = useState({});
  const [globalErrorMessage, setGlobalErrorMessage] = useState('');

  const openDetailsModal = part => {
    setCurrentPartImages(part.pictures);
    setCurrentPart(part);
    setShowDetailsModal(true);
  };

  const closeDetailsModal = () => {
    setShowDetailsModal(false);
  };

  // Messaging functions
  const openMessageModal = part => {
    setCurrentPart(part);
    setSubject(`Question about Part #${part.partNumber || ''}`);
    setBody('');
    setSendError('');
    setGlobalErrorMessage('');
    setShowMessageModal(true);
  };

  const closeMessageModal = () => {
    setShowMessageModal(false);
    setGlobalErrorMessage('');
    setSendError('');
  };

  const handleSendMessage = async () => {
    if (!subject || !body) {
      setSendError('Subject and body are required.');
      return;
    }

    setSending(true);
    setSendError('');
    setGlobalErrorMessage(''); // Clear any previous global errors

    const messageData = {
      recipientCompanyId: currentPart.companyid || currentPart.companyId,
      subject,
      body,
      relatedPartId: currentPart.id, // IMPORTANT: Assuming 'id' is the PartSearchResult ID
      messageType: 'part'
    };

    try {
      const response = await fetch(`${baseUrl}/api/message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        },
        body: JSON.stringify(messageData)
      });

      const result = await response.json();
      if (response.ok) {
        // Update partMessages with a success message for this part
        setPartMessages(prevMessages => ({
          ...prevMessages,
          [currentPart.id]: `Message successfully sent to ${currentPart.companyName || 'the seller'}.`
        }));
        closeMessageModal();
      } else {
        // Close the modal and set the global error message
        closeMessageModal();
        setGlobalErrorMessage(result.message || 'Failed to send message.');
      }
    } catch (err) {
      // Close the modal and set the global error message
      closeMessageModal();
      setGlobalErrorMessage('An error occurred while sending the message.');
    } finally {
      setSending(false);
    }
  };

  const formatDate = dateString => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // This will give you "yyyy-mm-dd"
  };

  const handleShowMore = () => {
    setVisibleCount(prevCount => prevCount + 25); // Increment by 25 each time
  };
  const updatedPartSearches = partSearches.map(search => ({
    ...search,
    PartSearchResults: search.PartSearchResults.map(part => {
      let pictures;

      if (part.pics.includes(';')) {
        pictures = part.pics
          .split('; ')
          .map((url, idx) => ({ url: url.trim(), picId: idx }));
      } else {
        pictures = [
          {
            url: part.pics.trim(),
            picId: 0
          }
        ];
      }

      return {
        ...part,
        pictures
      };
    })
  }));

  if (searchResults.length === 0 || searchResults === null)
    return <p>No parts searched for yet.</p>;

  return (
    <div
      style={{
        position: 'relative',
        minHeight: '100vh',
        paddingTop: '10px'
      }}
    >
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {globalErrorMessage && (
        <div style={{ color: 'red', marginTop: '10px', textAlign: 'center' }}>
          {globalErrorMessage}
        </div>
      )}
      {updatedPartSearches.slice(0, visibleCount).map((search, index) => (
        <React.Fragment key={search.id}>
          <Card
            style={{
              backgroundColor: '#add8e6',
              paddingBottom: '75px',
              marginBottom: '30px'
            }}
            key={search.id}
          >
            {search.partsMatched > 0 ? (
              <>
                <Card.Header
                  style={{
                    paddingTop: '20px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '0'
                  }}
                >
                  <p
                    style={{
                      fontSize: '18px',
                      paddingLeft: '5px',
                      fontWeight: 'bold',
                      textAlign: 'left'
                    }}
                  >
                    Results for {search.partNumber}
                  </p>
                  <p
                    style={{
                      fontSize: '18px',
                      fontWeight: 'bold',
                      textAlign: 'right',
                      paddingRight: '20px'
                    }}
                  >
                    {formatDate(search.createdAt)}
                  </p>
                </Card.Header>
                <Table
                  className="table-striped"
                  style={{ margin: '0', padding: '0' }}
                >
                  <thead>
                    <tr>
                      <th style={{ paddingLeft: '20px' }}>Company Name</th>
                      <th>Part Number</th>
                      <th>Description</th>
                      <th>Condition</th>
                      <th>Price</th>
                      <th>Pictures</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {search.PartSearchResults.map(part => (
                      <tr key={part.id}>
                        <td style={{ paddingLeft: '20px' }}>
                          <button
                            onClick={() => openDetailsModal(part)}
                            type="button"
                            style={{
                              all: 'unset',
                              cursor: 'pointer',
                              display: 'block',
                              width: '100%'
                            }}
                          >
                            {part.companyName}
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() => openDetailsModal(part)}
                            type="button"
                            style={{
                              all: 'unset',
                              cursor: 'pointer',
                              display: 'block',
                              width: '100%'
                            }}
                          >
                            {part.partNumber}
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() => openDetailsModal(part)}
                            type="button"
                            style={{
                              all: 'unset',
                              cursor: 'pointer',
                              display: 'block',
                              width: '100%'
                            }}
                          >
                            {part.description}
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() => openDetailsModal(part)}
                            type="button"
                            style={{
                              all: 'unset',
                              cursor: 'pointer',
                              display: 'block',
                              width: '100%'
                            }}
                          >
                            {part.condlong}
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() => openDetailsModal(part)}
                            type="button"
                            style={{
                              all: 'unset',
                              cursor: 'pointer',
                              display: 'block',
                              width: '100%'
                            }}
                          >
                            {' '}
                            {part.quotedPrice === null ||
                            part.quotedPrice === undefined ||
                            part.quotedPrice === 0
                              ? 'Contact for price'
                              : `$${part.quotedPrice}`}{' '}
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() => openDetailsModal(part)}
                            type="button"
                            style={{
                              all: 'unset',
                              cursor: 'pointer',
                              display: 'block',
                              width: '100%'
                            }}
                          >
                            {' '}
                            <img
                              src={
                                part.pictures && part.pictures.length > 0
                                  ? part.pictures[0].url
                                  : '/images/image_not_available.png'
                              }
                              alt="Thumbnail for part images"
                              style={{ width: '60px', height: '50px' }}
                              onError={e => {
                                e.target.src =
                                  '/images/image_not_available.png';
                              }}
                            />
                          </button>
                        </td>
                        <td>
                          <Button
                            variant="primary"
                            onClick={() => openMessageModal(part)}
                          >
                            Send Message
                          </Button>
                          {partMessages[part.id] && (
                            <p style={{ color: 'green', marginTop: '5px' }}>
                              {partMessages[part.id]}
                            </p>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            ) : (
              <>
                <Card.Header
                  style={{
                    paddingTop: '20px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <p
                    style={{
                      fontSize: '18px',
                      paddingLeft: '5px',
                      fontWeight: 'bold',
                      textAlign: 'left'
                    }}
                  >
                    Results for {search.partNumber}
                  </p>
                  <p
                    style={{
                      fontSize: '18px',
                      fontWeight: 'bold',
                      textAlign: 'right',
                      paddingRight: '20px'
                    }}
                  >
                    {formatDate(search.createdAt)}
                  </p>
                </Card.Header>
                <div style={{ backgroundColor: 'white', paddingLeft: '10px' }}>
                  <p
                    style={{
                      textAlign: 'center',
                      fontSize: '18px',
                      paddingTop: '25px',
                      paddingBottom: '25px'
                    }}
                  >
                    No parts to display for Partnumber:{' '}
                    <b>{search.partNumber}</b>, Manufacturer:{' '}
                    <b>{search.manufacturer}</b>, Condition:{' '}
                    <b>{search.condition}</b> .
                  </p>
                </div>
              </>
            )}
          </Card>

          {/* Add the <hr> tag between cards, except after the last one */}
          {index !== partSearches.slice(0, visibleCount).length - 1 && (
            <hr
              style={{
                border: '0',
                height: '1px',
                background: '#333',
                margin: '30px 0'
              }}
            />
          )}
        </React.Fragment>
      ))}

      {visibleCount < partSearches.length && (
        <Button
          onClick={handleShowMore}
          style={{ margin: '20px auto', display: 'block' }}
        >
          Show More
        </Button>
      )}
      <PartDetailsModal
        show={showDetailsModal}
        onHide={closeDetailsModal}
        currentPart={currentPart}
        currentPartImages={currentPartImages}
        openMessageModal={openMessageModal}
        partMessages={partMessages}
      />
      {/* Message Modal */}
      <Modal
        show={showMessageModal}
        onHide={closeMessageModal}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Message to {currentPart.companyName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {sendError && <Alert variant="danger">{sendError}</Alert>}
          <Form>
            <Form.Group controlId="formSubject">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                value={subject}
                onChange={e => setSubject(e.target.value)}
                placeholder="Enter subject"
              />
            </Form.Group>
            <Form.Group controlId="formBody">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={body}
                onChange={e => setBody(e.target.value)}
                placeholder="Enter your message"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={closeMessageModal}
            disabled={sending}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSendMessage}
            disabled={sending}
          >
            {sending ? 'Sending...' : 'Send Message'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PartSearchResult;
