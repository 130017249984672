import React from 'react';
import { Carousel } from 'react-bootstrap';

function BoydAndSonsCarousel() {
  return (
    <Carousel>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_1.png"
            alt="BoydGallery1"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_2.jpeg"
            alt="BoydGallery2"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_3.jpeg"
            alt="BoydGallery3"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_4.jpeg"
            alt="BoydGallery4"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_5.jpeg"
            alt="BoydGallery5"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_6.jpeg"
            alt="BoydGallery6"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_7.jpeg"
            alt="BoydGallery7"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_8.jpeg"
            alt="BoydGallery8"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_9.jpeg"
            alt="BoydGallery9"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_10.jpeg"
            alt="BoydGallery10"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_11.jpeg"
            alt="BoydGallery11"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_12.jpeg"
            alt="BoydGallery12"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_13.jpeg"
            alt="BoydGallery13"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_14.jpeg"
            alt="BoydGallery14"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_15.jpeg"
            alt="BoydGallery15"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_16.jpeg"
            alt="BoydGallery16"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_17.jpeg"
            alt="BoydGallery17"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_18.jpeg"
            alt="BoydGallery18"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_19.jpeg"
            alt="BoydGallery19"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_20.jpeg"
            alt="BoydGallery20"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_21.jpeg"
            alt="BoydGallery21"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_22.jpeg"
            alt="BoydGallery22"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_23.jpeg"
            alt="BoydGallery23"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_24.jpeg"
            alt="BoydGallery24"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_25.jpeg"
            alt="BoydGallery25"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_26.jpeg"
            alt="BoydGallery26"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="http://www.boydandsons.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/boyd/Boyd_Gallery_27.jpeg"
            alt="BoydGallery27"
          />
        </a>
      </Carousel.Item>
    </Carousel>
  );
}

export default BoydAndSonsCarousel;
