import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

function Masthead({ backgroundImage, headerText, headerSubtext }) {
  const mastheadStyle = {
    height: '50vh',
    minHeight: '250px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${backgroundImage})`
  };

  return (
    <header style={mastheadStyle}>
      <Container className="h-100">
        <Row className="h-100 align-items-center">
          <Col xs={12} className="text-center">
            <h1 className="masthead_headertext">{headerText}</h1>
            <p className="masthead_headersubtext">{headerSubtext}</p>
          </Col>
        </Row>
      </Container>
    </header>
  );
}

// Define the prop types
Masthead.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  headerSubtext: PropTypes.string.isRequired
};

export default Masthead;
