import { useState } from "react";

const attachmentHook = (baseUrl) => {
  const [searchResults, setSearchResults] = useState(null);
  const [error, setError] = useState("");

  const searchAttachments = async (attachmentId) => {
    setError(""); // Clear any existing error message
    setSearchResults(null); // Clear previous search results

    try {
      const response = await fetch(
        `${baseUrl}/api/guestattachments/${attachmentId}`
      );
      const data = await response.json();

      // Check for a successful response with parts data
      if (response.ok && data.data && data.data.attachment) {
        setSearchResults(data.data.attachment);
      } else {
        // Handle API errors by displaying a message from the errors array if available
        const errorMessage = data.errors?.length
          ? data.errors[0]
          : "Failed to fetch results";
        setError(errorMessage);
      }
    } catch (err) {
      setError("Failed to fetch results");
      // eslint-disable-next-line no-console
      console.error(err); // Log the error for debugging purposes
    }
  };

  return {
    searchResults,
    error,
    searchAttachments,
  };
};

export default attachmentHook;
