import { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { isTokenExpired } from '../utils/jwtUtils';
import refreshAccessToken from '../utils/refreshToken';

const useBrowsePart = baseUrl => {
  const { accessToken, refreshToken, updateTokens, logout } = useAuth();
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState('');
  const [partCount, setPartCount] = useState([]);

  const browseParts = async (manufacturer = '', page = 1, limit = 50) => {
    let currentAccessToken = accessToken;

    // Check and refresh the token if it's expired
    if (!currentAccessToken || isTokenExpired(currentAccessToken)) {
      const refreshedTokenData = await refreshAccessToken(
        baseUrl,
        refreshToken,
        updateTokens,
        logout
      );

      if (!refreshedTokenData) {
        // Instead of navigating directly, call the logout function
        logout('Your session has expired. Please log in again.');
        return;
      }
      currentAccessToken = refreshedTokenData.accessToken;
    }

    setError(''); // Clear any existing error message
    setSearchResults([]); // Clear previous search results

    const determinePrice = (minPrice, maxPrice) => {
      const isEmpty = value =>
        value === 0 ||
        value === '' ||
        value === null ||
        value === undefined ||
        Number.isNaN(value);

      const formatPrice = value =>
        `$${Number(value).toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })}`;

      if (isEmpty(minPrice) && isEmpty(maxPrice)) {
        // Both minPrice and maxPrice are empty or 0
        return 'Contact for Price';
      }

      if (isEmpty(minPrice) && !isEmpty(maxPrice)) {
        // minPrice is empty or 0, maxPrice is defined and not empty
        return formatPrice(maxPrice);
      }

      if (!isEmpty(minPrice) && isEmpty(maxPrice)) {
        // minPrice is defined and not empty, maxPrice is empty or 0
        return formatPrice(minPrice);
      }

      if (!isEmpty(minPrice) && !isEmpty(maxPrice)) {
        if (minPrice === maxPrice) {
          // Both minPrice and maxPrice are defined, not 0, and equal
          return formatPrice(minPrice);
        }
        // Both minPrice and maxPrice are defined, not 0, and not equal
        return `${formatPrice(minPrice)} - ${formatPrice(maxPrice)}`;
      }

      // Fallback
      return 'Contact for Price';
    };

    try {
      const url = new URL(`${baseUrl}/api/browseparts/`);
      const params = new URLSearchParams();

      if (manufacturer && manufacturer !== 'All') {
        params.append('manufacturer', manufacturer);
      }

      if (page) {
        params.append('page', page);
      }

      if (limit) {
        params.append('limit', limit); // Corrected condition to append limit
      }

      url.search = params.toString();

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${currentAccessToken}`
        }
      });

      const data = await response.json();

      // Check for a successful response with parts data
      if (response.ok && data.data && Array.isArray(data.data.parts)) {
        const processedParts = data.data.parts.map(part => {
          const minPrice = Number(part.minprice);
          const maxPrice = Number(part.maxprice);

          let price = determinePrice(minPrice, maxPrice);

          if (price === '$0') {
            price = 'Contact for Price';
          }
          if (price !== 'Contact for Price' && part.hasnoprice === 1) {
            price = `${price} Or Contact for Price`;
          }
          return {
            ...part,
            price
          };
        });

        setSearchResults(processedParts);
        setPartCount(data.data.count);
      } else if (response.ok && data.message) {
        setError(data.message);
      } else {
        const errorMessage = data.errors?.length
          ? data.errors[0]
          : 'Failed to fetch results';
        setError(errorMessage);
        setSearchResults([]); // No parts due to error
      }
    } catch (err) {
      setError('Failed to fetch results');
      // eslint-disable-next-line no-console
      console.error(err); // Log the error for debugging purposes
    }
  };

  return { searchResults, partCount, error, browseParts };
};

export default useBrowsePart;
