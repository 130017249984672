import React, { useEffect } from 'react';
import { Row, Col, Card, Carousel } from 'react-bootstrap';
import attachmentHook from '@netcom-network-v2/shared/src/hooks/attachmentHook';
import PropTypes from 'prop-types';

function TextWithLineBreaks({ text, id }) {
  return (
    <div>
      {text.split('\n').map(line => (
        <p key={`${line}-${id}`}>{line}</p>
      ))}
    </div>
  );
}

function AttachmentSearch({ attachmentId = null }) {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { searchResults, filenames, picurls, error, searchAttachments } =
    attachmentHook(baseUrl);

  useEffect(() => {
    // Call the searchAttachments function when the component mounts
    searchAttachments(attachmentId);
  }, []);
  let useBlankImage = 0;
  if (filenames.length === 0 && picurls.length === 0) {
    useBlankImage = 1;
  } else {
    useBlankImage = 0;
  }

  return (
    <div>
      {searchResults &&
        searchResults.map(attachment => (
          <div key={`Attachment ${attachment.id}`}>
            <h1 className="pb-3">
              {attachment.category.charAt(0).toUpperCase() +
                attachment.category.slice(1).toLowerCase()}{' '}
              Details
            </h1>
            <Card className="text-dark bg-light border-light mt-3">
              <Row>
                <Col xs={12} m={12} lg={12} xl={12}>
                  <Card.Header className="mb-0">
                    <h3>
                      <center>{attachment.title}</center>
                    </h3>
                  </Card.Header>
                  <Card.Body className="m-0 p-0">
                    <Row className="g-0">
                      <Col className="col-md-7 col-lg-8 border-end border-bottom">
                        <Carousel>
                          {picurls.map((picurl, index) => (
                            <Carousel.Item
                              key={picurl.picnum || `picurl-${index}`}
                            >
                              <img
                                className="d-block w-100"
                                src={picurl.picurl}
                                alt={`PicURL Gallery ${picurl.picnum}`}
                                onError={e => {
                                  e.target.src =
                                    '/images/image_not_available.png';
                                }}
                              />
                            </Carousel.Item>
                          ))}
                          {filenames.map((filename, index) => (
                            <Carousel.Item
                              key={filename.picnum || `filename-${index}`}
                            >
                              <img
                                className="d-block w-100"
                                src={`https://pf-dev-pics.s3.amazonaws.com/forsale/${filename.filename}`}
                                alt={`Filename Gallery ${filename.picnum}`}
                                onError={e => {
                                  e.target.src =
                                    '/images/image_not_available.png';
                                }}
                              />
                            </Carousel.Item>
                          ))}
                          {useBlankImage === 1 && (
                            <Carousel.Item key={`no_pic_error_${Date.now()}`}>
                              <img
                                className="d-block w-100"
                                src="/images/image_not_available.png"
                                alt="No Pic Attachment"
                              />
                            </Carousel.Item>
                          )}
                        </Carousel>
                      </Col>

                      <Col md={5} lg={4} className="border-bottom m-0 p-0">
                        <Card.Body>
                          <Row className="g-0">
                            <div className="col-4 text-end text-muted pe-2">
                              <p>Model #:</p>
                            </div>
                            <div className="col-8">
                              <h5>{attachment.modelnum}</h5>
                            </div>

                            <div className="col-4 text-end text-muted pe-2">
                              <p>Price:</p>
                            </div>
                            <div className="col-8">
                              <h5>
                                {attachment.price > 0
                                  ? `$${attachment.price}`
                                  : ''}
                              </h5>
                            </div>

                            <div className="col-4 text-end text-muted pe-2">
                              <p>Manuf #:</p>
                            </div>
                            <div className="col-8">
                              <h5>{attachment.manuflong}</h5>
                            </div>

                            <div className="col-4 text-end text-muted pe-2">
                              <p>Cond:</p>
                            </div>
                            <div className="col-8">
                              <h5> {attachment.condlong}</h5>
                            </div>

                            <div className="col-4 text-end text-muted pe-2">
                              <p>Category:</p>
                            </div>
                            <div className="col-8">
                              <h5> {attachment.subcategory}</h5>
                            </div>
                          </Row>
                        </Card.Body>
                        <Card.Body className="mx-0 px-0">
                          <Row className="g-0">
                            <div className="bg-secondary text-white text-center py-1 mb-3">
                              <h4>Seller Information</h4>
                            </div>

                            <div className="col-4 text-end text-muted pe-2">
                              <p>Company:</p>
                            </div>
                            <div className="col-8">
                              <h5> {attachment.companyname}</h5>
                            </div>

                            <div className="col-4 text-end text-muted pe-2">
                              <p>Phone:</p>
                            </div>
                            <div className="col-8">
                              <h5> {attachment.phone}</h5>
                            </div>

                            <div className="col-4 text-end text-muted pe-2">
                              <p>Location:</p>
                            </div>
                            <div className="col-8">
                              <h5>
                                {attachment.city && attachment.state
                                  ? `${attachment.city}, ${attachment.state}`
                                  : attachment.city || attachment.state}
                              </h5>
                            </div>

                            <div className="col-4 text-end text-muted pe-2">
                              <p>Website:</p>
                            </div>
                            <div className="col-8">
                              <h5>
                                <a href={attachment.website}>
                                  {attachment.website}
                                </a>
                              </h5>
                            </div>
                          </Row>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Row>
                    <h4 className="ps-3">More Details</h4>
                    <Col md={7} lg={8}>
                      <Card.Body>
                        <Row>
                          <div className="col-4 col-lg-2 text-end text-muted pe-2">
                            <p>S/N:</p>
                          </div>
                          <div className="col-8 col-lg-4">
                            <h6> {attachment.serialnum}</h6>
                          </div>

                          <div className="col-4 col-lg-2 text-end text-muted pe-2">
                            <p>Stock Num:</p>
                          </div>
                          <div className="col-8 col-lg-4">
                            <h5>{attachment.stocknum}</h5>
                          </div>
                          <div className="col-4 col-lg-2 text-end text-muted pe-2">
                            <p>Year:</p>
                          </div>
                          <div className="col-8 col-lg-4">
                            <h6>{attachment.modelyear}</h6>
                          </div>
                          <div className="col-4 col-lg-2 text-end text-muted pe-2">
                            <p>Hours:</p>
                          </div>
                          <div className="col-8 col-lg-4">
                            <h6>{attachment.hours}</h6>
                          </div>
                          <div className="col-4 col-lg-2 text-end text-muted pe-2">
                            <p>Dimensions:</p>
                          </div>
                          <div className="col-8 col-lg-4">
                            <h6>{attachment.dimensions}</h6>
                          </div>
                          <div className="col-4 col-lg-2 text-end text-muted pe-2">
                            <p>Weight:</p>
                          </div>
                          <div className="col-8 col-lg-4">
                            <h6>{attachment.weight}</h6>
                          </div>
                          <div className="col-4 col-lg-2 text-end text-muted pe-2">
                            <p>Location:</p>
                          </div>
                          <div className="col-8 col-lg-4">
                            {attachment.location &&
                              attachment.location.trim() !== '0' && (
                                <h6>{attachment.location}</h6>
                              )}
                          </div>
                        </Row>
                      </Card.Body>
                    </Col>

                    <div className="col-md-5 col-lg-4 border-bottom pb-4 px-3">
                      <span className="text-muted">Comments:</span>
                      <TextWithLineBreaks
                        text={attachment.details}
                        id={attachment.attachmentId}
                      />

                      {/* <p className="card-text">{attachment.details}</p> */}

                      <span className="text-muted">Shipping Notes:</span>
                      <p className="card-text">{attachment.shipnotes}</p>
                    </div>
                  </Row>
                  <Card.Footer>
                    <p className="text-muted text-end">
                      Last Updated:{attachment.datemod}
                    </p>
                  </Card.Footer>
                </Col>
              </Row>
            </Card>
          </div>
        ))}
      {error && <p>Error: {error}</p>}
    </div>
  );
}

AttachmentSearch.propTypes = {
  // eslint-disable-next-line react/require-default-props
  attachmentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

TextWithLineBreaks.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
};
export default AttachmentSearch;
