import { useState } from 'react';

const useGuestBrowsePart = baseUrl => {
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState('');
  const [partCount, setPartCount] = useState([]);

  const guestBrowseParts = async (manufacturer = '', page = 1, limit = 50) => {
    setError(''); // Clear any existing error message
    setSearchResults([]); // Clear previous search results

    try {
      const url = new URL(`${baseUrl}/api/guestbrowseparts/`);
      const params = new URLSearchParams();

      if (manufacturer && manufacturer !== 'All') {
        params.append('manufacturer', manufacturer);
      }

      if (page) {
        params.append('page', page);
      }

      if (limit) {
        params.append('limit', limit); // Corrected condition to append limit
      }

      url.search = params.toString();

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();

      // Check for a successful response with parts data
      if (response.ok && data.data && data.data.parts) {
        setSearchResults(data.data.parts);
        setPartCount(data.data.count);
      } else if (response.ok && data.message) {
        setError(data.message);
      } else {
        const errorMessage = data.errors?.length
          ? data.errors[0]
          : 'Failed to fetch results';
        setError(errorMessage);
        setSearchResults([]); // No parts due to error
      }
    } catch (err) {
      setError('Failed to fetch results');
      // eslint-disable-next-line no-console
      console.error(err); // Log the error for debugging purposes
    }
  };

  return { searchResults, partCount, error, guestBrowseParts };
};

export default useGuestBrowsePart;
