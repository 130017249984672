import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card, Carousel } from 'react-bootstrap';
import guestAttachmentHook from '@netcom-network-v2/shared/src/hooks/guestAttachmentHook';
import PropTypes from 'prop-types';

function AttachmentSearch({ attachmentId = null }) {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { searchResults, error, searchAttachments } =
    guestAttachmentHook(baseUrl);
  const navigate = useNavigate();

  useEffect(() => {
    searchAttachments(attachmentId);
  }, []);
  const openCreateAccount = () => {
    navigate('/register');
  };
  return (
    <div>
      {searchResults &&
        searchResults.map(attachment => (
          <div key={`Attachment ${attachment.id}`}>
            <h1 className="pb-3">
              {attachment.category.charAt(0).toUpperCase() +
                attachment.category.slice(1).toLowerCase()}{' '}
              Details
            </h1>
            <Card className="text-dark bg-light border-light mt-3">
              <Row>
                <Col xs={12} m={12} lg={12} xl={12}>
                  <Card.Header className="mb-0">
                    <h3>
                      <center>{attachment.title}</center>
                    </h3>
                  </Card.Header>
                  <Card.Body className="m-0 p-0">
                    <Row className="g-0">
                      <Col className="col-md-7 col-lg-8 border-end border-bottom">
                        <Carousel>
                          <Carousel.Item key={`no_pic_error_${Date.now()}`}>
                            <img
                              className="d-block w-100"
                              src="/images/image_not_available.png"
                              alt="No Pic Attachment"
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                filter: 'blur(8px)'
                              }}
                            />
                            <div
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.3)' // Optional
                              }}
                            >
                              <button
                                onClick={openCreateAccount}
                                type="button"
                                style={{
                                  all: 'unset',
                                  cursor: 'pointer',

                                  padding: '10px 20px',
                                  fontSize: '18px',
                                  color: '#fff',
                                  backgroundColor: 'rgba(0, 123, 255, 0.8)',
                                  borderRadius: '5px'
                                }}
                              >
                                Create Account to View
                              </button>
                            </div>
                          </Carousel.Item>
                        </Carousel>
                      </Col>

                      <Col md={5} lg={4} className="border-bottom m-0 p-0">
                        <Card.Body>
                          <Row className="g-0">
                            <div className="col-4 text-end text-muted pe-2">
                              <p>Model #:</p>
                            </div>
                            <div className="col-8">
                              <h5>{attachment.modelnum}</h5>
                            </div>

                            <div className="col-4 text-end text-muted pe-2">
                              <p>Price:</p>
                            </div>
                            <div
                              className="col-8"
                              style={{
                                filter: 'blur(4px)'
                              }}
                            >
                              <h5>
                                <img
                                  src="/images/justprice.png"
                                  alt="Thumbnail for part images"
                                  style={{
                                    width: '25%',
                                    height: '25%'
                                  }}
                                />
                              </h5>
                            </div>

                            <div className="col-4 text-end text-muted pe-2">
                              <p>Manuf #:</p>
                            </div>
                            <div className="col-8">
                              <h5>{attachment.manuflong}</h5>
                            </div>

                            <div className="col-4 text-end text-muted pe-2">
                              <p>Cond:</p>
                            </div>
                            <div className="col-8">
                              <h5> {attachment.condlong}</h5>
                            </div>

                            <div className="col-4 text-end text-muted pe-2">
                              <p>Category:</p>
                            </div>
                            <div className="col-8">
                              <h5> {attachment.subcategory}</h5>
                            </div>
                          </Row>
                        </Card.Body>
                        <Card.Body className="mx-0 px-0">
                          <Row className="g-0">
                            <div className="bg-secondary text-white text-center py-1 mb-3">
                              <h4>Seller Information</h4>
                            </div>
                            <div
                              style={{
                                filter: 'blur(5px)'
                              }}
                            >
                              <img
                                src="/images/fakecompanyinfo.png"
                                alt="Thumbnail for part images"
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  display: 'flex'
                                }}
                              />
                            </div>
                          </Row>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Row>
                    <h4 className="ps-3">More Details</h4>
                    <Col md={7} lg={8}>
                      <Card.Body>
                        <Row>
                          <div className="col-4 col-lg-2 text-end text-muted pe-2">
                            <p>S/N:</p>
                          </div>
                          <div className="col-8 col-lg-4">
                            <h6> {attachment.serialnum}</h6>
                          </div>

                          <div className="col-4 col-lg-2 text-end text-muted pe-2">
                            <p>Stock Num:</p>
                          </div>
                          <div className="col-8 col-lg-4">
                            <h5>{attachment.stocknum}</h5>
                          </div>
                          <div className="col-4 col-lg-2 text-end text-muted pe-2">
                            <p>Year:</p>
                          </div>
                          <div className="col-8 col-lg-4">
                            <h6>{attachment.modelyear}</h6>
                          </div>
                          <div className="col-4 col-lg-2 text-end text-muted pe-2">
                            <p>Hours:</p>
                          </div>
                          <div className="col-8 col-lg-4">
                            <h6>{attachment.hours}</h6>
                          </div>
                          <div className="col-4 col-lg-2 text-end text-muted pe-2">
                            <p>Dimensions:</p>
                          </div>
                          <div className="col-8 col-lg-4">
                            <h6>{attachment.dimensions}</h6>
                          </div>
                          <div className="col-4 col-lg-2 text-end text-muted pe-2">
                            <p>Weight:</p>
                          </div>
                          <div className="col-8 col-lg-4">
                            <h6>{attachment.weight}</h6>
                          </div>
                          <div className="col-4 col-lg-2 text-end text-muted pe-2">
                            <p>Location:</p>
                          </div>
                          <div
                            className="col-8 col-lg-4"
                            style={{
                              filter: 'blur(5px)'
                            }}
                          >
                            <img
                              src="/images/justlocation.png"
                              alt="Thumbnail for location"
                              style={{
                                width: '70%',
                                height: '70%',
                                display: 'flex'
                              }}
                            />
                          </div>
                        </Row>
                      </Card.Body>
                    </Col>

                    <div className="col-md-5 col-lg-4 border-bottom pb-4 px-3 position-relative">
                      <img
                        src="/images/forsalenotes.png"
                        alt="Thumbnail for part images"
                        style={{
                          filter: 'blur(4px)',
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          display: 'block'
                        }}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          pointerEvents: 'none'
                        }}
                      >
                        <button
                          onClick={openCreateAccount}
                          type="button"
                          style={{
                            cursor: 'pointer',
                            padding: '10px 20px',
                            fontSize: '18px',
                            color: '#fff',
                            backgroundColor: 'rgba(0, 123, 255, 0.8)',
                            border: 'none',
                            borderRadius: '5px',
                            pointerEvents: 'auto' // Makes the button clickable
                          }}
                        >
                          Create Account to View
                        </button>
                      </div>
                    </div>
                  </Row>
                  <Card.Footer>
                    <p className="text-muted text-end">
                      Last Updated:{attachment.datemod}
                    </p>
                  </Card.Footer>
                </Col>
              </Row>
            </Card>
          </div>
        ))}
      {error && <p>Error: {error}</p>}
    </div>
  );
}

AttachmentSearch.propTypes = {
  // eslint-disable-next-line react/require-default-props
  attachmentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default AttachmentSearch;
