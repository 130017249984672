import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Alert } from 'react-bootstrap';
import LoginForm from '../components/User/LoginForm';
import { useAuth } from '../context/AuthContext';

function LoginPage() {
  const { logoutMessage, setLogoutMessage } = useAuth();

  useEffect(() => {
    // Log when useEffect runs due to logoutMessage changes
    if (logoutMessage) {
      // Set a timeout to clear the message after a set duration
      const timer = setTimeout(() => {
        setLogoutMessage('');
      }, 5000); // Clear the message after 5 seconds

      // Clean up the timeout when the component is unmounted or when logoutMessage changes again before the timeout completes
      return () => clearTimeout(timer);
    }
    return () => {}; // Noop function for ESLint
  }, [logoutMessage, setLogoutMessage]);

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} sm={10} md={9} lg={8} xl={6}>
          <Card className="p-4 mt-4 mb-5 shadow-sm">
            <Card.Body>
              {logoutMessage && (
                <Alert variant="warning" className="text-center">
                  {logoutMessage}
                </Alert>
              )}
              <h2 className="text-center mb-4">Log In</h2>
              <LoginForm />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default LoginPage;
