import React from 'react';
import { Container, Row, Col, Modal, Carousel } from 'react-bootstrap';

function HowItWorksGrid() {
  const galleryImages = [
    {
      id: 'HowItWorks_1',
      src: '/images/marketplace-solutions/HowItWorks_1.jpeg',
      alt: 'Image 1'
    },
    {
      id: 'HowItWorks_2',
      src: '/images/marketplace-solutions/HowItWorks_2.jpeg',
      alt: 'Image 2'
    }
  ];

  const [modalShow, setModalShow] = React.useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = React.useState(null);

  const openModal = index => {
    setSelectedImageIndex(index);
    setModalShow(true);
  };

  const closeModal = () => {
    setModalShow(false);
  };

  const handleSelect = selectedIndex => {
    setSelectedImageIndex(selectedIndex);
  };

  return (
    <Container className="mb-4" data-testid="howitworks-grid">
      <Row>
        {galleryImages.map((image, index) => (
          <Col
            key={image.id}
            xs={12}
            sm={4}
            md={4}
            lg={6}
            onClick={() => openModal(index)}
          >
            <img
              id={image.id}
              className="marketplace-image d-block w-100 mb-4"
              src={image.src}
              alt={image.alt}
            />
          </Col>
        ))}
      </Row>

      <Modal
        key="KeyForGridModal"
        className="marketplace-modal"
        show={modalShow}
        onHide={closeModal}
        centered
        size="lg"
        data-testid="marketplace-grid-modal"
      >
        <Modal.Body>
          <Carousel
            activeIndex={selectedImageIndex}
            onSelect={handleSelect}
            className="marketplace-carousel"
          >
            {galleryImages.map(image => (
              <Carousel.Item key={image.id}>
                <img
                  className="d-block w-100"
                  src={image.src}
                  alt={image.alt}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default HowItWorksGrid;
