import { useState } from "react";

const filterListHook = (baseUrl) => {
  const [companyList, setCompanyList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [error, setError] = useState("");

  const filterList = async (
    companyMod = "",
    subCategoryMod = "",
    category = ""
  ) => {
    setError("");
    setCompanyList([]);
    setSubCategoryList([]);

    try {
      let url = `${baseUrl}/api/filterList/`;
      const params = new URLSearchParams();
      if (companyMod && companyMod !== "All Companies")
        params.append("companyMod", companyMod);
      if (subCategoryMod && subCategoryMod !== "All Categories")
        params.append("subCategoryMod", subCategoryMod);
      if (category) params.append("category", category);
      url += `?${params.toString()}`;

      const response = await fetch(url);
      const data = await response.json();

      if (response.ok) {
        if (data.data) {
          setCompanyList(data.data.companyList || []);
          setSubCategoryList(data.data.subCategoryList || []);
        } else if (data.message) {
          setError(data.message);
        } else {
          setError("No companies or categories found and no message provided.");
        }
      } else {
        const errorMessage = data.errors?.length
          ? data.errors.join(", ")
          : "Failed to fetch results";
        setError(errorMessage);
      }
    } catch (err) {
      setError("Network error 101 or unable to connect to the server");
    }
  };

  return {
    companyList,
    subCategoryList,
    error,
    filterList,
  };
};

export default filterListHook;
