import React from 'react';
import { Form, Button, Alert, Container } from 'react-bootstrap';
import useCompanyRegistration from '../../hooks/useCompanyRegistration';

function CompanyForm() {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const {
    formData,
    handleChange,
    handleSubmit,
    errors,
    isSubmitting,
    successMessage
  } = useCompanyRegistration(apiBaseUrl);

  const handleAutofillBillingAddress = () => {
    handleChange({
      target: {
        name: 'billAddress1',
        value: formData.shipAddress1
      }
    });
    handleChange({
      target: {
        name: 'billAddress2',
        value: formData.shipAddress2
      }
    });
    handleChange({
      target: {
        name: 'billCity',
        value: formData.shipCity
      }
    });
    handleChange({
      target: {
        name: 'billState',
        value: formData.shipState
      }
    });
    handleChange({
      target: {
        name: 'billZip',
        value: formData.shipZip
      }
    });
    handleChange({
      target: {
        name: 'billCountry',
        value: formData.shipCountry
      }
    });
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        {successMessage && <Alert variant="success">{successMessage}</Alert>}

        {errors.form && <Alert variant="danger">{errors.form}</Alert>}

        <Form.Group controlId="name">
          <Form.Label>Company Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            isInvalid={!!errors.name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="phoneMain">
          <Form.Label>Main Phone</Form.Label>
          <Form.Control
            type="tel"
            name="phoneMain"
            value={formData.phoneMain}
            onChange={handleChange}
            isInvalid={!!errors.phoneMain}
          />
          <Form.Control.Feedback type="invalid">
            {errors.phoneMain}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="phoneSecondary">
          <Form.Label>Secondary Phone (Optional)</Form.Label>
          <Form.Control
            type="tel"
            name="phoneSecondary"
            value={formData.phoneSecondary}
            onChange={handleChange}
            isInvalid={!!errors.phoneSecondary}
          />
          <Form.Control.Feedback type="invalid">
            {errors.phoneSecondary}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="fax">
          <Form.Label>Fax (Optional)</Form.Label>
          <Form.Control
            type="tel"
            name="fax"
            value={formData.fax}
            onChange={handleChange}
            isInvalid={!!errors.fax}
          />
          <Form.Control.Feedback type="invalid">
            {errors.fax}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="shipAddress1">
          <Form.Label>Shipping Address 1</Form.Label>
          <Form.Control
            type="text"
            name="shipAddress1"
            value={formData.shipAddress1}
            onChange={handleChange}
            isInvalid={!!errors.shipAddress1}
          />
          <Form.Control.Feedback type="invalid">
            {errors.shipAddress1}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="shipAddress2">
          <Form.Label>Shipping Address 2 (Optional)</Form.Label>
          <Form.Control
            type="text"
            name="shipAddress2"
            value={formData.shipAddress2}
            onChange={handleChange}
            isInvalid={!!errors.shipAddress2}
          />
          <Form.Control.Feedback type="invalid">
            {errors.shipAddress2}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="shipCity">
          <Form.Label>Shipping City</Form.Label>
          <Form.Control
            type="text"
            name="shipCity"
            value={formData.shipCity}
            onChange={handleChange}
            isInvalid={!!errors.shipCity}
          />
          <Form.Control.Feedback type="invalid">
            {errors.shipCity}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="shipState">
          <Form.Label>Shipping State</Form.Label>
          <Form.Control
            type="text"
            name="shipState"
            value={formData.shipState}
            onChange={handleChange}
            isInvalid={!!errors.shipState}
          />
          <Form.Control.Feedback type="invalid">
            {errors.shipState}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="shipZip">
          <Form.Label>Shipping Zip Code</Form.Label>
          <Form.Control
            type="text"
            name="shipZip"
            value={formData.shipZip}
            onChange={handleChange}
            isInvalid={!!errors.shipZip}
          />
          <Form.Control.Feedback type="invalid">
            {errors.shipZip}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="shipCountry">
          <Form.Label>Shipping Country</Form.Label>
          <Form.Control
            type="text"
            name="shipCountry"
            value={formData.shipCountry}
            onChange={handleChange}
            isInvalid={!!errors.shipCountry}
          />
          <Form.Control.Feedback type="invalid">
            {errors.shipCountry}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="mb-3 mt-3">
          <Button
            style={{
              backgroundColor: '#007bff', // Bootstrap 'info' color
              color: 'white',
              padding: '8px 12px',
              borderRadius: '5px',
              border: 'none',
              transition: 'background-color 0.3s ease'
            }}
            onClick={handleAutofillBillingAddress}
          >
            <i className="fas fa-copy" style={{ marginRight: '5px' }} />
            Copy Shipping to Billing
          </Button>
        </div>
        <Form.Group controlId="billAddress1">
          <Form.Label>Billing Address 1</Form.Label>
          <Form.Control
            type="text"
            name="billAddress1"
            value={formData.billAddress1}
            onChange={handleChange}
            isInvalid={!!errors.billAddress1}
          />
          <Form.Control.Feedback type="invalid">
            {errors.billAddress1}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="billAddress2">
          <Form.Label>Billing Address 2 (Optional)</Form.Label>
          <Form.Control
            type="text"
            name="billAddress2"
            value={formData.billAddress2}
            onChange={handleChange}
            isInvalid={!!errors.billAddress2}
          />
          <Form.Control.Feedback type="invalid">
            {errors.billAddress2}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="billCity">
          <Form.Label>Billing City</Form.Label>
          <Form.Control
            type="text"
            name="billCity"
            value={formData.billCity}
            onChange={handleChange}
            isInvalid={!!errors.billCity}
          />
          <Form.Control.Feedback type="invalid">
            {errors.billCity}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="billState">
          <Form.Label>Billing State</Form.Label>
          <Form.Control
            type="text"
            name="billState"
            value={formData.billState}
            onChange={handleChange}
            isInvalid={!!errors.billState}
          />
          <Form.Control.Feedback type="invalid">
            {errors.billState}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="billZip">
          <Form.Label>Billing Zip Code</Form.Label>
          <Form.Control
            type="text"
            name="billZip"
            value={formData.billZip}
            onChange={handleChange}
            isInvalid={!!errors.billZip}
          />
          <Form.Control.Feedback type="invalid">
            {errors.billZip}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="billCountry">
          <Form.Label>Billing Country</Form.Label>
          <Form.Control
            type="text"
            name="billCountry"
            value={formData.billCountry}
            onChange={handleChange}
            isInvalid={!!errors.billCountry}
          />
          <Form.Control.Feedback type="invalid">
            {errors.billCountry}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="email1">
          <Form.Label>Primary Email</Form.Label>
          <Form.Control
            type="email"
            name="email1"
            value={formData.email1}
            onChange={handleChange}
            isInvalid={!!errors.email1}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email1}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="email2">
          <Form.Label>Secondary Email (Optional)</Form.Label>
          <Form.Control
            type="email"
            name="email2"
            value={formData.email2}
            onChange={handleChange}
            isInvalid={!!errors.email2}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email2}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="email3">
          <Form.Label>Tertiary Email (Optional)</Form.Label>
          <Form.Control
            type="email"
            name="email3"
            value={formData.email3}
            onChange={handleChange}
            isInvalid={!!errors.email3}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email3}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="partsManager">
          <Form.Label>Parts Manager</Form.Label>
          <Form.Control
            type="text"
            name="partsManager"
            value={formData.partsManager}
            onChange={handleChange}
            isInvalid={!!errors.partsManager}
          />
          <Form.Control.Feedback type="invalid">
            {errors.partsManager}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="contactList">
          <Form.Label>Contact List (Optional)</Form.Label>
          <Form.Control
            type="text"
            name="contactList"
            value={formData.contactList}
            onChange={handleChange}
            isInvalid={!!errors.contactList}
          />
          <Form.Control.Feedback type="invalid">
            {errors.contactList}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="website">
          <Form.Label>Website (Optional)</Form.Label>
          <Form.Control
            type="url"
            name="website"
            value={formData.website}
            onChange={handleChange}
            isInvalid={!!errors.website}
          />
          <Form.Control.Feedback type="invalid">
            {errors.website}
          </Form.Control.Feedback>
        </Form.Group>

        <div className="d-flex justify-content-center mt-4">
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Add Company
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default CompanyForm;
