import React from 'react';
import { Carousel } from 'react-bootstrap';

function ThompsonCarousel() {
  return (
    <Carousel>
      <Carousel.Item>
        <a
          href="https://thompsonmachinery.com/parts/used-parts/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/thompson/Thompson_Gallery_Header.jpeg"
            alt="ThompsonGallery1"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://thompsonmachinery.com/parts/used-parts/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/thompson/Thompson_Carousel_2.jpeg"
            alt="ThompsonGallery2"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://thompsonmachinery.com/parts/used-parts/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/thompson/Thompson_Carousel_3.jpeg"
            alt="ThompsonGallery3"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://thompsonmachinery.com/parts/used-parts/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/thompson/Thompson_Carousel_4.jpeg"
            alt="ThompsonGallery4"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://thompsonmachinery.com/parts/used-parts/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/thompson/Thompson_Carousel_5.jpeg"
            alt="ThompsonGallery5"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://thompsonmachinery.com/parts/used-parts/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/thompson/Thompson_Carousel_6.jpeg"
            alt="ThompsonGallery6"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://thompsonmachinery.com/parts/used-parts/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/thompson/Thompson_Carousel_7.jpeg"
            alt="ThompsonGallery7"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://thompsonmachinery.com/parts/used-parts/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/thompson/Thompson_Carousel_8.jpeg"
            alt="ThompsonGallery8"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://thompsonmachinery.com/parts/used-parts/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/thompson/Thompson_Carousel_9.jpeg"
            alt="ThompsonGallery9"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://thompsonmachinery.com/parts/used-parts/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/thompson/Thompson_Carousel_10.jpeg"
            alt="ThompsonGallery10"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://thompsonmachinery.com/parts/used-parts/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/thompson/Thompson_Carousel_11.jpeg"
            alt="ThompsonGallery11"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://thompsonmachinery.com/parts/used-parts/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/thompson/Thompson_Carousel_12.jpeg"
            alt="ThompsonGallery12"
          />
        </a>
      </Carousel.Item>
    </Carousel>
  );
}

export default ThompsonCarousel;
