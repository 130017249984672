import React from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';

function HowItWorksModal() {
  const galleryImages = [
    {
      id: 'HowItWorks_3',
      src: '/images/marketplace-solutions/HowItWorks_3.jpeg',
      alt: 'Image 3',
      paragraph: '2) Customer selects items to be included in quote.'
    },
    {
      id: 'HowItWorks_4',
      src: '/images/marketplace-solutions/HowItWorks_4.jpeg',
      alt: 'Image 4',
      paragraph: '3) Customer selects shipping options.'
    },
    {
      id: 'HowItWorks_5',
      src: '/images/marketplace-solutions/HowItWorks_5.jpeg',
      alt: 'Image 5',
      paragraph: '4) Customer requests a quote.'
    },
    {
      id: 'HowItWorks_6',
      src: '/images/marketplace-solutions/HowItWorks_6.jpeg',
      alt: 'Image 6',
      paragraph:
        '5) Seller responds to quote with confirmed prices and shipping costs of order.'
    },
    {
      id: 'HowItWorks_7',
      src: '/images/marketplace-solutions/HowItWorks_7.jpeg',
      alt: 'Image 7',
      paragraph: '6a) Customer pays for order.',
      paragraph2: '6b) Seller updates order as paid.'
    },
    {
      id: 'HowItWorks_8',
      src: '/images/marketplace-solutions/HowItWorks_8.jpeg',
      alt: 'Image 8',
      paragraph:
        '7) Seller updates customers with shipping details including tracking numbers.'
    }
  ];

  const [modalShow, setModalShow] = React.useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = React.useState(null);

  // eslint-disable-next-line no-unused-vars
  const openModal = imageId => {
    const imageIndex = galleryImages.findIndex(image => image.id === imageId);
    setSelectedImageIndex(imageIndex);
    setModalShow(true);
  };

  const closeModal = () => {
    setModalShow(false);
  };

  return (
    <Container className="mb-4" data-testid="howitworks-modal">
      {galleryImages.map(image => (
        <Row key={image.id}>
          <Col
            xs={12}
            sm={4}
            md={8}
            lg={12}
            onClick={() => openModal(image.id)}
          >
            <p>{image.paragraph}</p>
            {image.paragraph2 && <p className="mt-5">{image.paragraph2}</p>}
            <img
              id={image.id}
              className="marketplace-image d-block w-100 mb-5"
              src={image.src}
              alt={image.alt}
            />
          </Col>
        </Row>
      ))}

      <Modal
        className="marketplace-modal"
        show={modalShow}
        onHide={closeModal}
        centered
        size="xl"
        data-testid="marketplace-modal"
      >
        <Modal.Body>
          <img
            className="d-block w-100"
            src={galleryImages[selectedImageIndex]?.src}
            alt={galleryImages[selectedImageIndex]?.alt}
          />
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default HowItWorksModal;
