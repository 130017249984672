import { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { isTokenExpired } from '../utils/jwtUtils';
import refreshAccessToken from '../utils/refreshToken';

const usePartSearchResult = baseUrl => {
  const { accessToken, refreshToken, updateTokens, logout } = useAuth();
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState('');
  const [partSearches, setPartSearches] = useState([]);

  const processParts = parts =>
    parts.reduce(
      (accumulator, part) => accumulator.concat(part.PartSearchResults || []),
      []
    );

  const searchParts = async () => {
    let currentAccessToken = accessToken;

    // Check and refresh the token if it's expired
    if (!currentAccessToken || isTokenExpired(currentAccessToken)) {
      const refreshedTokenData = await refreshAccessToken(
        baseUrl,
        refreshToken,
        updateTokens,
        logout
      );

      if (!refreshedTokenData) {
        logout('Your session has expired. Please log in again.');
        return;
      }
      currentAccessToken = refreshedTokenData.accessToken;
    }

    setError('');
    setSearchResults([]);

    try {
      const response = await fetch(`${baseUrl}/api/partResults`, {
        method: 'GET', // Method is optional here as GET is the default value
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${currentAccessToken}`
        }
      });
      const data = await response.json();

      // Check for a successful response with parts data
      if (response.ok && data.data && data.data.parts) {
        const allPartSearches = data.data.parts;
        const allPartSearchResults = processParts(data.data.parts);
        setSearchResults(allPartSearchResults);
        setPartSearches(allPartSearches);
      } else if (response.ok && data.message) {
        // Handle cases where the response is ok, but no parts are found
        setError(data.message);
      } else {
        // Handle API errors by displaying a message from the errors array if available
        const errorMessage = data.errors?.length
          ? data.errors[0]
          : 'Failed to fetch results';
        setError(errorMessage);
      }
    } catch (err) {
      setError('Failed to fetch results');
      // eslint-disable-next-line no-console
      console.error(err); // Log the error for debugging purposes
    }
  };

  return { searchResults, partSearches, error, searchParts };
};

export default usePartSearchResult;
