import React from 'react';
import PartSearch from '../../components/PartSearch';

function PartSearchPage() {
  return (
    <div>
      <PartSearch />
    </div>
  );
}

export default PartSearchPage;
