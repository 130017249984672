let cachedIp = null;

const fetchIpAddress = async () => {
  if (cachedIp) {
    return cachedIp;
  }

  const response = await fetch('https://api.ipify.org?format=json');
  const data = await response.json();
  cachedIp = data.ip;
  return cachedIp;
};
export default fetchIpAddress;
