import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function CompanyProtectedRoute() {
  const { accessToken, userInfo, attemptTokenRefresh } = useAuth();
  const [isChecking, setIsChecking] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const checkToken = async () => {
      await attemptTokenRefresh(); // Attempt to refresh token if necessary
      setIsChecking(false); // Set checking to false after the attempt
    };

    checkToken();
  }, [attemptTokenRefresh]);

  if (isChecking) {
    // Optionally, you could render a loading spinner or a blank page while checking
    return <div>Loading...</div>;
  }

  if (!accessToken) {
    // If after checking, there's no access token, redirect to login page
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Check if the user has a company
  if (!userInfo.companyId) {
    // If user is logged in but has no company associated, redirect to add company page with message
    return (
      <Navigate
        to="/profile"
        state={{
          from: location,
          message:
            'You must add companny information in order to have access to this feature.'
        }}
        replace
      />
    );
  }

  return <Outlet />; // Render children routes if the token exists and user has a company
}

export default CompanyProtectedRoute;
