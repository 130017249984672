import React from 'react';
import { Carousel } from 'react-bootstrap';

function CarterCarousel() {
  return (
    <Carousel>
      <Carousel.Item>
        <a
          href="https://www.cartermachinery.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/carter/Carter_Gallery_1.jpeg"
            alt="CarterGallery1"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.cartermachinery.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/carter/Carter_Gallery_2.jpeg"
            alt="CarterGallery2"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.cartermachinery.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/carter/Carter_Gallery_3.jpeg"
            alt="CarterGallery3"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.cartermachinery.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/carter/Carter_Gallery_4.jpeg"
            alt="CarterGallery4"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.cartermachinery.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/carter/Carter_Gallery_5.jpeg"
            alt="CarterGallery5"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.cartermachinery.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/carter/Carter_Gallery_6.jpeg"
            alt="CarterGallery6"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.cartermachinery.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/carter/Carter_Gallery_7.jpeg"
            alt="CarterGallery7"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.cartermachinery.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/carter/Carter_Gallery_8.jpeg"
            alt="CarterGallery8"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.cartermachinery.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/carter/Carter_Gallery_9.jpeg"
            alt="CarterGallery9"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.cartermachinery.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/carter/Carter_Gallery_10.jpeg"
            alt="CarterGallery10"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.cartermachinery.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/carter/Carter_Gallery_11.jpeg"
            alt="CarterGallery11"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.cartermachinery.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/carter/Carter_Gallery_12.jpeg"
            alt="CarterGallery12"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.cartermachinery.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/carter/Carter_Gallery_13.jpeg"
            alt="CarterGallery13"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.cartermachinery.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/carter/Carter_Gallery_14.jpeg"
            alt="CarterGallery14"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.cartermachinery.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/carter/Carter_Gallery_15.jpeg"
            alt="CarterGallery15"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.cartermachinery.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/carter/Carter_Gallery_16.jpeg"
            alt="CarterGallery16"
          />
        </a>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarterCarousel;
