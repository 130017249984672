import React from 'react';
import { Form, Button, Alert, Container } from 'react-bootstrap';
import useUserRegistration from '../../hooks/useUserRegistration';

function UserRegistrationForm() {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const {
    formData,
    handleChange,
    handleSubmit,
    errors,
    isSubmitting,
    successMessage
  } = useUserRegistration(apiBaseUrl);

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        {successMessage && <Alert variant="success">{successMessage}</Alert>}

        {errors.form && <Alert variant="danger">{errors.form}</Alert>}

        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            isInvalid={!!errors.email}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            isInvalid={!!errors.password}
          />
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="firstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            isInvalid={!!errors.firstName}
          />
          <Form.Control.Feedback type="invalid">
            {errors.firstName}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="lastName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            isInvalid={!!errors.lastName}
          />
          <Form.Control.Feedback type="invalid">
            {errors.lastName}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="phoneOffice">
          <Form.Label>Office Phone (Optional)</Form.Label>
          <Form.Control
            type="tel"
            name="phoneOffice"
            value={formData.phoneOffice}
            onChange={handleChange}
            isInvalid={!!errors.phoneOffice}
          />
          <Form.Control.Feedback type="invalid">
            {errors.phoneOffice}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="phoneMobile">
          <Form.Label>Mobile Phone (Optional)</Form.Label>
          <Form.Control
            type="tel"
            name="phoneMobile"
            value={formData.phoneMobile}
            onChange={handleChange}
            isInvalid={!!errors.phoneMobile}
          />
          <Form.Control.Feedback type="invalid">
            {errors.phoneMobile}
          </Form.Control.Feedback>
        </Form.Group>

        <div className="d-flex justify-content-center mt-4">
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Sign Up
          </Button>
        </div>
      </Form>
      <div className="text-center my-4">
        <div className="border-top pt-3">or</div>
      </div>
      <div className="text-center mt-3">
        Already have an account? <a href="/login">Login</a>
      </div>
    </Container>
  );
}

export default UserRegistrationForm;
