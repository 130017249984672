import React from 'react';
import { Carousel } from 'react-bootstrap';

function HeavyQuipCarousel() {
  return (
    <Carousel>
      <Carousel.Item>
        <a
          href="https://dieselsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/headers/HeavyQuip_Header.jpeg"
            alt="HeavyQuipGallery1"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://dieselsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/heavyquip/HeavyQuip_Gallery_2.jpeg"
            alt="HeavyQuipGallery2"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://dieselsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/heavyquip/HeavyQuip_Gallery_3.jpeg"
            alt="HeavyQuipGallery3"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://dieselsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/heavyquip/HeavyQuip_Gallery_4.jpeg"
            alt="HeavyQuipGallery4"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://dieselsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/heavyquip/HeavyQuip_Gallery_5.jpeg"
            alt="HeavyQuipGallery5"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://dieselsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/heavyquip/HeavyQuip_Gallery_6.jpeg"
            alt="HeavyQuipGallery6"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://dieselsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/heavyquip/HeavyQuip_Gallery_101.jpg"
            alt="HeavyQuipGallery7"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://dieselsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/heavyquip/HeavyQuip_Gallery_102.jpg"
            alt="HeavyQuipGallery8"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://dieselsales.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/heavyquip/HeavyQuip_Gallery_103.jpg"
            alt="HeavyQuipGallery9"
          />
        </a>
      </Carousel.Item>
    </Carousel>
  );
}

export default HeavyQuipCarousel;
