import { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { isTokenExpired } from '../utils/jwtUtils';
import refreshAccessToken from '../utils/refreshToken';

const usePartSearch = baseUrl => {
  const { accessToken, refreshToken, updateTokens, logout } = useAuth();
  const [searchResults, setSearchResults] = useState(null);
  const [processedParts, setProcessedParts] = useState([]);
  const [error, setError] = useState('');

  const processParts = parts => {
    const partsMap = new Map();

    parts.forEach(item => {
      if (!partsMap.has(item.id)) {
        partsMap.set(item.id, {
          ...item,
          pictures: [] // You might want to adjust this if your data structure requires it
        });
      }
      const part = partsMap.get(item.id);
      part.pictures.push({
        filename: item.filename,
        url:
          item.picurl ||
          `https://pf-dev-pics.s3.amazonaws.com/parts/${item.filename}`,
        picId: item.picId
      });
    });

    return Array.from(partsMap.values());
  };

  const searchParts = async (
    partNumber,
    condition = '',
    manufacturer = '',
    description = '',
    comments = ''
  ) => {
    let currentAccessToken = accessToken;

    // Check and refresh the token if it's expired
    if (!currentAccessToken || isTokenExpired(currentAccessToken)) {
      const refreshedTokenData = await refreshAccessToken(
        baseUrl,
        refreshToken,
        updateTokens,
        logout
      );

      if (!refreshedTokenData) {
        // Instead of navigating directly, call the logout function
        logout('Your session has expired. Please log in again.');
        return;
      }
      currentAccessToken = refreshedTokenData.accessToken;
    }

    if (partNumber.length < 3) {
      setError('Part number must be at least 3 characters long.');
      setSearchResults(null);
      return;
    }
    if (description.length < 3) {
      setError('Description must be at least 3 characters long.');
      setSearchResults(null);
      return;
    }

    setError(''); // Clear any existing error message
    setSearchResults(null); // Clear previous search results

    try {
      const url = new URL(`${baseUrl}/api/parts/${partNumber}`);
      const params = new URLSearchParams();
      if (condition && condition !== '') {
        params.append('condition', condition);
      }
      if (manufacturer) {
        params.append('manufacturer', manufacturer);
      }
      params.append('description', description);
      if (comments) {
        params.append('comments', comments);
      }
      // Append parameters to the URL only if they exist
      url.search = params.toString();

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${currentAccessToken}`
        }
      });
      const data = await response.json();

      // Check for a successful response with parts data
      if (response.ok && data.data && data.data.parts) {
        setSearchResults(data.data.parts);
        setProcessedParts(processParts(data.data.parts));
      } else if (response.ok && data.message) {
        setError(data.message);
      } else {
        const errorMessage = data.errors?.length
          ? data.errors[0]
          : 'Failed to fetch results';
        setError(errorMessage);
      }
    } catch (err) {
      setError('Failed to fetch results');
      // eslint-disable-next-line no-console
      console.error(err); // Log the error for debugging purposes
    }
  };

  return { searchResults, processedParts, error, searchParts };
};

export default usePartSearch;
