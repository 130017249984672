import { useState } from 'react';

const useGuestPartSearch = baseUrl => {
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState('');

  const searchParts = async (
    partNumber = '',
    condition = '',
    manufacturer = '',
    ipAddress = ''
  ) => {
    if (partNumber.length < 3) {
      setError('Part number must be at least 3 characters long.');
      setSearchResults([]);
      return;
    }

    setError(''); // Clear any existing error message
    setSearchResults([]); // Clear previous search results

    try {
      const url = new URL(`${baseUrl}/api/guestparts/${partNumber}`);
      const params = new URLSearchParams();
      if (condition && condition !== '') {
        params.append('condition', condition);
      }
      if (manufacturer) {
        params.append('manufacturer', manufacturer);
      }
      if (ipAddress) {
        params.append('ipAddress', ipAddress);
      }
      // Append parameters to the URL only if they exist
      url.search = params.toString();

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();

      // Check for a successful response with parts data
      if (response.ok && data.data && data.data.parts) {
        setSearchResults(data.data.parts);
      } else if (response.ok && data.message) {
        setError(data.message);
      } else {
        const errorMessage = data.errors?.length
          ? data.errors[0]
          : 'Failed to fetch results';
        setError(errorMessage);
      }
    } catch (err) {
      setError('Failed to fetch results');
      // eslint-disable-next-line no-console
      console.error(err); // Log the error for debugging purposes
    }
  };

  return { searchResults, error, searchParts };
};

export default useGuestPartSearch;
