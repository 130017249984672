import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function PartsNetworkQuickQuote() {
  return (
    <Container>
      <Row>
        <Col>
          <h2>Quick Quote</h2>
          <p>
            Quick Quote allows you to instantly quote the price of your parts
            and is included in Parts Network membership.
          </p>
          <p>How It Works:</p>
          <ul>
            <li>Dealer decides which parts to instantly quote prices.</li>
            <li>
              When someone searches for a part they will instantly see the
              condition and price and receive a detailed part quote message.
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <img
          className="quickquote_image"
          src="/images/partsNetwork/QuickQuote_1.jpeg"
          alt="QuickQuote"
        />
      </Row>
      <Row>
        <img
          className="quickquote_image mt-3"
          src="/images/partsNetwork/QuickQuote_2.jpeg"
          alt="QuickQuote"
        />
      </Row>
    </Container>
  );
}

export default PartsNetworkQuickQuote;
