import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function PartsNetworkFeatures() {
  return (
    <Container>
      <Row>
        <h2>Key Features</h2>
        <Col>
          <ul>
            <li>
              Parts importing is customized for each dealer and optimized for
              the most part matches possible.
            </li>
            <li>
              Dealers are notified instantly every time one of their parts is
              searched for. All part searches go out as a message viewable by
              every member.
            </li>
            <li>Search for up to 20 parts at once.</li>
            <li>
              “Quick Quote” feature displays a price and sends a detailed quote
              the instant a part is searched for.
            </li>
            <li>Private member to member messaging system.</li>
            <li>Multiple accounts</li>
          </ul>
        </Col>
        <h2>More Features</h2>
        <Col>
          <ul>
            <li>
              Ability to add description(s), model number(s) and other details
              to every part search.
            </li>
            <li>
              Every search returns a list of the conditions and companies that
              have part in stock.
            </li>
            <li>
              Receive a notification every time someone searches for your part.
            </li>
            <li>
              Bulk Search & Match Import feature lets you create a part request
              message out of part numbers stored in a file.
            </li>
            <li>
              Send and view “need to locate” messages for unusual items that
              don&apos;t have a common part number.
            </li>
            <li>
              Send and view non business messages that help you get to know
              others in the parts business.
            </li>
            <li>Send and view suggestions to improve parts network.</li>
            <li>
              New message alerts notify you when you have received a new private
              message or when someone has matched one of your parts.
            </li>
            <li>
              Ability to locate parts by specific conditions including Used,
              New, Aftermarket, Rebuilt and more.
            </li>
            <li>Attach pictures to messages.</li>
            <li>
              Advanced search tools for searching messages you have received or
              sent by company, part number, search string, date range and more.
            </li>
            <li>
              Detailed member lists including contact and location information.
            </li>
            <li>Skype Integration.</li>
            <li>
              Dismantled Machines database. Search for machines that have
              part(s) you need. Add your own dismantled machines.
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default PartsNetworkFeatures;
