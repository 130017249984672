/**
 * Calculates the indices where "Create Account" buttons should appear.
 *
 * @param {number} length - The total number of items.
 * @returns {Set<number>} - A set containing the indices for button placement.
 */
const calculateButtonIndices = length => {
  const indices = new Set();

  if (length <= 15) {
    // Calculate the middle index
    const middle = Math.floor(length / 2);
    indices.add(middle);
  } else {
    // Add the 5th row (index 4)
    indices.add(4);

    // Add every 10th row after the 5th (i.e., 15th, 25th, ...)
    for (let i = 14; i < length; i += 10) {
      indices.add(i);
    }
  }

  return indices;
};

export default calculateButtonIndices;
