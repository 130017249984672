import React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import AttachmentSearch from '../../components/ForSale/GuestAttachmentDetails';

function GuestAttachmentViewPage() {
  const { id } = useParams();
  return (
    <div>
      <Container className="py-3">
        <AttachmentSearch attachmentId={id} />
      </Container>
    </div>
  );
}

export default GuestAttachmentViewPage;
