import { jwtDecode } from 'jwt-decode';

export const decodeToken = token => {
  try {
    return jwtDecode(token);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Decoding token failed:', error);
    return null;
  }
};

export const isTokenExpired = token => {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // convert to seconds
    return decoded.exp < currentTime;
  } catch (error) {
    // handle error, e.g., token is not valid JWT
    return true;
  }
};
