import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HowItWorksGrid from './HowItWorksGrid';
import HowItWorksModal from './HowItWorksModal';

function HowItWorks() {
  return (
    <Container>
      <Row>
        <Col>
          <h2>How It Works</h2>
          <p>
            Our custom designed marketplace solution provides the following
            customer experience:
          </p>
          <p>1) Customer searches for parts.</p>
          <HowItWorksGrid />
        </Col>
      </Row>
      <Row>
        <Col>
          <HowItWorksModal key="uniqueKeyForModal" />
        </Col>
      </Row>
    </Container>
  );
}

export default HowItWorks;
