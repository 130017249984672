import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function GettingStarted() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Getting Started</h1>
          <p>
            To get started on the Network you can either have a limited view as
            a guest or create an account and get full access. After creating an
            account you will need to add a company to your account by clicking
            the Add Company Information button under the Company Contact
            Information header. After these are set up you can browse our
            extensive list of construction parts, attachments, machines and
            more. Enjoy the ease of our inhouse messaging system allowing you to
            easily get priced out on anything you are interested in as well as
            negotiating price.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default GettingStarted;
