import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';

const useMessage = baseUrl => {
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [currentFolder, setCurrentFolder] = useState('Inbox');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalMessages, setTotalMessages] = useState(0);

  const { accessToken, userInfo } = useAuth();

  // Fetch messages
  const fetchMessages = useCallback(async () => {
    setLoadingMessages(true);
    try {
      const response = await fetch(
        `${baseUrl}/api/messages?folder=${currentFolder}&sortOrder=${sortOrder}&page=${page}&pageSize=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      const result = await response.json();

      if (response.ok) {
        setMessages(result.data.messages);
        setTotalMessages(result.data.count);
      } else {
        // eslint-disable-next-line no-console
        console.error('Error fetching messages:', result);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching messages:', error);
    } finally {
      setLoadingMessages(false);
    }
  }, [accessToken, baseUrl, currentFolder, sortOrder, page, pageSize]);

  // Mark a message as read
  const markMessageAsRead = async (messageId, folder) => {
    try {
      await fetch(`${baseUrl}/api/messages/${messageId}/read`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({ folder })
      });

      // Update the message's isRead status in the local state
      setMessages(prevMessages =>
        prevMessages.map(msg => {
          if (msg.id === messageId) {
            // Update the UserMessage for the current user
            const updatedUserMessages = msg.UserMessages.map(um => {
              if (um.userId === userInfo.userId && um.folder === folder) {
                return { ...um, isRead: true };
              }
              return um;
            });
            return { ...msg, UserMessages: updatedUserMessages };
          }
          return msg;
        })
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error marking message as read:', error);
    }
  };

  // Mark a message as unread
  const markMessageAsUnread = async (messageId, folder) => {
    try {
      await fetch(`${baseUrl}/api/messages/${messageId}/unread`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({ folder })
      });

      // Update the message's isRead status in the local state
      setMessages(prevMessages =>
        prevMessages.map(msg => {
          if (msg.id === messageId) {
            // Update the UserMessage for the current user and folder
            const updatedUserMessages = msg.UserMessages.map(um => {
              if (um.userId === userInfo.userId && um.folder === folder) {
                return { ...um, isRead: false };
              }
              return um;
            });
            return { ...msg, UserMessages: updatedUserMessages };
          }
          return msg;
        })
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error marking message as unread:', error);
    }
  };

  // Toggle the starred status of a message
  const toggleStarredStatus = async (messageId, folder) => {
    try {
      const response = await fetch(
        `${baseUrl}/api/messages/${messageId}/star`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify({ folder }) // Include folder in the request body
        }
      );

      const result = await response.json();

      if (response.ok) {
        const { isStarred } = result.data;
        // Update the message's isStarred status in the local state
        setMessages(prevMessages =>
          prevMessages.map(msg => {
            if (msg.id === messageId) {
              // Update the UserMessage for the current user
              const updatedUserMessages = msg.UserMessages.map(um => {
                if (um.userId === userInfo.userId && um.folder === folder) {
                  return { ...um, isStarred };
                }
                return um;
              });
              return { ...msg, UserMessages: updatedUserMessages };
            }
            return msg;
          })
        );
      } else {
        // eslint-disable-next-line no-console
        console.error('Error toggling starred status:', result);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error toggling message starred status:', error);
    }
  };

  // Send a reply to a message
  const sendReply = async (messageId, replyBody) => {
    try {
      const response = await fetch(
        `${baseUrl}/api/messages/${messageId}/reply`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify({ body: replyBody })
        }
      );

      const result = await response.json();

      if (response.ok) {
        // Fetch the updated message from the backend
        const messageResponse = await fetch(
          `${baseUrl}/api/messages/${messageId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );

        const messageResult = await messageResponse.json();

        if (messageResponse.ok) {
          const updatedMessage = messageResult.data.message;

          // Update the message in the local state
          setMessages(prevMessages =>
            prevMessages.map(msg => {
              if (msg.id === messageId) {
                return updatedMessage;
              }
              return msg;
            })
          );

          // Return the updated message
          return updatedMessage;
        }

        // eslint-disable-next-line no-console
        console.error('Error fetching updated message:', messageResult);
        return null;
      }

      // eslint-disable-next-line no-console
      console.error('Error sending reply:', result);
      // Return null to indicate failure
      return null;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error sending reply:', error);
      // Return null to indicate failure
      return null;
    }
  };

  // Fetch messages when the hook is used
  useEffect(() => {
    if (accessToken) {
      fetchMessages();
    }
  }, [accessToken, fetchMessages]);

  return {
    messages,
    loadingMessages,
    fetchMessages,
    markMessageAsRead,
    markMessageAsUnread,
    toggleStarredStatus,
    sendReply,
    setCurrentFolder,
    sortOrder,
    setSortOrder,
    page,
    setPage,
    pageSize,
    totalMessages,
    setTotalMessages
  };
};

export default useMessage;
