import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Alert, Spinner, Container } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import refreshAccessToken from '../utils/refreshToken';

function VerifyEmailPage() {
  const [searchParams] = useSearchParams();
  const { refreshToken, updateTokens, logout } = useAuth();
  const navigate = useNavigate();
  const [status, setStatus] = useState('verifying'); // 'verifying', 'success', 'error'
  const [message, setMessage] = useState('');

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const token = searchParams.get('token');
  const email = searchParams.get('email');

  const hasVerified = useRef(false);

  useEffect(() => {
    if (hasVerified.current) {
      return; // Prevent multiple verification attempts
    }
    hasVerified.current = true;

    if (!token || !email) {
      setStatus('error');
      setMessage('Invalid verification link.');
      return;
    }

    const verifyEmail = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/api/verify-email?token=${encodeURIComponent(
            token
          )}&email=${encodeURIComponent(email)}`
        );

        const data = await response.json();

        if (response.ok) {
          setStatus('success');
          setMessage('Your email has been verified successfully.');

          // Refresh tokens to update email_verified status
          const tokens = await refreshAccessToken(
            baseUrl,
            refreshToken,
            updateTokens,
            logout
          );

          if (!tokens) {
            // If refreshing tokens failed, log the user out
            logout('Your session expired. Please log in again.');
          }
        } else {
          setStatus('error');
          setMessage(data.message || 'Email verification failed.');
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Email verification error:', error);
        setStatus('error');
        setMessage('An error occurred during email verification.');
      }
    };

    verifyEmail();

    // The dependency array is empty to ensure the effect runs only once
  }, []); // <-- Empty dependency array

  const handleGoToProfile = () => {
    navigate('/profile');
  };

  return (
    <Container className="mt-5">
      {status === 'verifying' && (
        <div className="text-center">
          <Spinner animation="border" role="status" />
          <p>Verifying your email...</p>
        </div>
      )}
      {status === 'success' && (
        <Alert variant="success">
          <h4>Email Verified</h4>
          <p>{message}</p>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleGoToProfile}
          >
            Go to Profile
          </button>
        </Alert>
      )}
      {status === 'error' && (
        <Alert variant="danger">
          <h4>Email Verification Failed</h4>
          <p>{message}</p>
        </Alert>
      )}
    </Container>
  );
}

export default VerifyEmailPage;
