import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Masthead from '../components/Masthead';
import ContactUs from '../components/ContactUs';

function ContactPage() {
  return (
    <div>
      <Masthead
        backgroundImage="/images/headers/ContactUsHeader.jpeg"
        headerText="Contact Us"
        headerSubtext=""
      />
      <Container className="py-3">
        <Row>
          <Col xs={12} lg={7} xl={8}>
            <ContactUs />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ContactPage;
