import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import InventoryManagerGrid from './InventoryManagerGrid';

function PartsNetworkInventoryManager() {
  return (
    <Container>
      <Row>
        <Col>
          <h2>Inventory Manager</h2>
          <p>
            Parts Network membership includes access to our web based inventory
            management program.
          </p>
          <ul>
            <li>
              Customizable inventory management program designed specifically
              for the heavy construction equipment industry.
            </li>
            <li>
              Choose from over 25 fields including multiple fields for pricing
              and location tracking.
            </li>
            <li>
              Choose only the fields you want and display them in the exact
              order you specify for each part of the application.
            </li>
            <li>
              Export or email your parts with only the fields and format (excel
              or text) you select.
            </li>
            <li>
              Searchable &quot;Parts History&quot; provides the ability to view
              all changes to a specific part over time.
            </li>
            <li>
              All changes to your inventory are updated instantly in the NetCom
              Parts Network.
            </li>
            <li>
              View and manage your inventory from multiple computers at the same
              time.
            </li>
          </ul>
          <InventoryManagerGrid />
        </Col>
      </Row>
    </Container>
  );
}

export default PartsNetworkInventoryManager;
