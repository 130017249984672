import React from 'react';
import { Container, Row, Col, Modal, Carousel } from 'react-bootstrap';

function InventoryManagerGrid() {
  const galleryImages = [
    {
      id: 'IM_1',
      src: '/images/partsNetwork/IMGallery_1.jpeg',
      alt: 'Image 1'
    },
    {
      id: 'IM_2',
      src: '/images/partsNetwork/IMGallery_2.jpeg',
      alt: 'Image 2'
    },
    {
      id: 'IM_3',
      src: '/images/partsNetwork/IMGallery_3.jpeg',
      alt: 'Image 3'
    },
    {
      id: 'IM_4',
      src: '/images/partsNetwork/IMGallery_4.jpeg',
      alt: 'Image 4'
    },
    {
      id: 'IM_5',
      src: '/images/partsNetwork/IMGallery_5.jpeg',
      alt: 'Image 5'
    },
    {
      id: 'IM_6',
      src: '/images/partsNetwork/IMGallery_6.jpeg',
      alt: 'Image 6'
    },
    {
      id: 'IM_7',
      src: '/images/partsNetwork/IMGallery_7.jpeg',
      alt: 'Image 7'
    },
    {
      id: 'IM_8',
      src: '/images/partsNetwork/IMGallery_8.jpeg',
      alt: 'Image 8'
    },
    {
      id: 'IM_9',
      src: '/images/partsNetwork/IMGallery_9.jpeg',
      alt: 'Image 9'
    },
    {
      id: 'IM_10',
      src: '/images/partsNetwork/IMGallery_10.jpeg',
      alt: 'Image 10'
    },
    {
      id: 'IM_11',
      src: '/images/partsNetwork/IMGallery_11.jpeg',
      alt: 'Image 11'
    }
  ];

  const [modalShow, setModalShow] = React.useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = React.useState(null);

  const openModal = index => {
    setSelectedImageIndex(index);
    setModalShow(true);
  };

  const closeModal = () => {
    setModalShow(false);
  };

  const handleSelect = selectedIndex => {
    setSelectedImageIndex(selectedIndex);
  };

  return (
    <Container className="mb-4" data-testid="inventory-manager-grid">
      <Row>
        {galleryImages.map((image, index) => (
          <Col
            key={image.id}
            xs={12}
            sm={6}
            md={4}
            lg={4}
            onClick={() => openModal(index)}
          >
            <img
              className="IMGrid d-block w-100 mb-4"
              src={image.src}
              alt={image.alt}
            />
          </Col>
        ))}
      </Row>

      <Modal
        className="pn-modal"
        show={modalShow}
        onHide={closeModal}
        centered
        size="xl"
        data-testid="inventory-modal"
      >
        <Modal.Body>
          <Carousel
            activeIndex={selectedImageIndex}
            onSelect={handleSelect}
            className="pn-carousel"
          >
            {galleryImages.map(image => (
              <Carousel.Item key={image.id}>
                <img
                  className="d-block w-100"
                  src={image.src}
                  alt={image.alt}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default InventoryManagerGrid;
