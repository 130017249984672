import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const useUserRegistration = apiBaseUrl => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    phoneOffice: '',
    phoneMobile: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const { updateTokens } = useAuth();
  const navigate = useNavigate();

  // Function to validate input fields
  const validate = () => {
    const tempErrors = {};
    if (!formData.email) {
      tempErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = 'Email is invalid';
    }
    if (!formData.password) {
      tempErrors.password = 'Password is required';
    }
    if (!formData.firstName) {
      tempErrors.firstName = 'First Name is required';
    }
    if (!formData.lastName) {
      tempErrors.lastName = 'Last Name is required';
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Function to handle form input changes
  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  // Function to submit the registration form
  const handleSubmit = async e => {
    e.preventDefault();
    if (!validate()) return; // Exit if validation fails

    setIsSubmitting(true);
    setErrors({}); // Clear existing errors
    setSuccessMessage(''); // Clear existing success message
    try {
      const response = await fetch(`${apiBaseUrl}/api/user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      const data = await response.json();

      if (response.ok) {
        setSubmissionStatus('success');

        // Update tokens if provided by the registration endpoint
        updateTokens(data.data.accessToken, data.data.refreshToken);

        // Optionally reset form here if needed
        setFormData({
          email: '',
          password: '',
          firstName: '',
          lastName: '',
          phoneOffice: '',
          phoneMobile: ''
        });

        const message = 'Account created successfully. You are now logged in.';
        setSuccessMessage(message); // State update for any other uses
        navigate('/profile', { state: { message } });
      } else {
        // Handle other types of errors (e.g., server error, not found, etc.)
        setSubmissionStatus('error');
        // Check if the errors array exists and has at least one error message
        if (data.errors && data.errors.length > 0) {
          // Example: Setting the first error from the errors array as a general form error
          setErrors({ form: data.errors[0] });
        } else {
          // Fallback error message if errors array is not available
          setErrors({
            form: data.message || 'An error occurred during registration.'
          });
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Registration error:', error);
      setSubmissionStatus('error');
      setErrors({ form: 'An error occurred during registration.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    formData,
    handleChange,
    handleSubmit,
    errors,
    isSubmitting,
    submissionStatus,
    successMessage
  };
};

export default useUserRegistration;
