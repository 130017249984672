import React from 'react';
import GuestPartSearch from '../../components/GuestPartSearch';

function GuestPartSearchPage() {
  return (
    <div>
      <GuestPartSearch />
    </div>
  );
}

export default GuestPartSearchPage;
