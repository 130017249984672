import React from 'react';
import PartSearchResult from '../../components/PartSearchResult';

function PartSearchResultPage() {
  return (
    <div>
      <PartSearchResult />
    </div>
  );
}

export default PartSearchResultPage;
