import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function StoreFrontIntro() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Custom Heavy Construction Equipment Storefront</h1>
          <p>
            Sell your parts using our custom storefront solution specifically
            designed for the heavy construction industry. This is not your
            typical cookie cutter storefront.
          </p>
          <h2>Key Features:</h2>
          <ul>
            <li>Payment processor of your choice (PayPal, Stripe, etc.)</li>
            <li>
              Searchable inventory with varying pricing levels based on type of
              customer (preferred dealer, dealer, retail customer, and more).
            </li>
            <li>Sell your inventory 24 hours a day, seven days a week.</li>
            <li>
              Ability to sell from a variety of vendors from multiple locations
              and multiple inventory files.
            </li>
            <li>
              Customers can designate preferred shipping methods including
              freight account details.
            </li>
            <li>
              Monitor status of orders from initial quote through payment and
              shipment.
            </li>
          </ul>
          <h2>Customer Features:</h2>
          <ul>
            <li>
              Customers can Request Quotes with the following: multiple part
              numbers, quantity desired for each part and shipping options.
              Shipping options include freight company, shipping destination
              address, PO number, special instructions, blind ship, certificate
              of origin, commercial invoice, NAFTA document, export declaration,
              and more by request.
            </li>
            <li>
              View Quote History. Includes part numbers, descriptions, estimated
              total and status of quote.
            </li>
            <li>
              View Order History and Details. Includes part numbers,
              descriptions, order total, status (which may include shipment
              tracking numbers)
            </li>
            <li>Request Photo(s).</li>
            <li>
              Ability to have multiple accounts all linked to the same company.
            </li>
          </ul>
          <h2>Seller Features:</h2>
          <ul>
            <li>
              Option to only show instant pricing and location of part only to
              registered and/or approved customers.
            </li>
            <li>
              View Order History. Includes company name, part number(s),
              description(s), item count, order total and status (awaiting
              approval, awaiting payment, confirm shipping, shipped, canceled,
              etc.)
            </li>
            <li>
              View Order Details. Can modify shipping information, prices,
              remove parts from order, add payment notes, add seller note
            </li>
            <li>Cancel Orders</li>
            <li>
              Mark order as paid with alternative methods (ACH, EFT, Check,
              etc.) with ability to add a payment note showing how order was
              paid.
            </li>
            <li>
              Customized freight options. This includes customized freight
              categories like UPS, FedEx, International. Each category will have
              further choices like UPS 2nd Day Air, UPS Ground, etc.
            </li>
            <li>
              Customize the information you&apos;d like a customer to see when
              searching for a part such as Description, Manufacturer, Condition,
              Location, Weight, Dimensions, etc.
            </li>
            <li>
              Manage companies that have access to the site. Can turn on/off the
              following: show retail prices, show wholesale prices, approve for
              credit. Can also delete company accounts.
            </li>
            <li>
              Parts can be inserted into the database from multiple files in
              multiple formats. Custom conversion and insertion programs are
              written for every inventory file that is added to the marketplace.
            </li>
            <li>Integrate with existing company websites.</li>
            <li>
              Customize with your company logo, tagline, phone number, etc.
            </li>
            <li>
              Provide your own legal notices, privacy policy, terms of use, etc.
            </li>
            <li>Web scraping detection and prevention.</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default StoreFrontIntro;
