import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';
import manufListHook from '@netcom-network-v2/shared/src/hooks/manufListHook';
import TableCarousel from './TableCarousel';
import useBrowsePart from '../hooks/useBrowsePart';

function BrowsePart() {
  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const [manufacturer, setManufacturer] = useState('All'); // State for condition
  const { searchResults, partCount, error, browseParts } =
    useBrowsePart(baseUrl);
  const { manufList } = manufListHook(baseUrl);
  const [page, setPage] = useState(1);
  const limit = 50; // Fixed limit, or make it state if you want to change it

  useEffect(() => {
    browseParts(manufacturer, page, limit);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [manufacturer, page, limit]);

  const handleManufacturerChange = e => {
    setManufacturer(e.target.value);
    setPage(1); // Reset to first page when manufacturer changes
  };

  return (
    <div
      style={{
        position: 'relative',
        minHeight: '100vh',
        marginLeft: '25px',
        marginRight: '25px'
      }}
    >
      <header
        style={{
          margin: '20px', // Reduced margin for better spacing
          padding: '20px 30px', // Balanced padding
          borderRadius: '8px',
          backgroundColor: '#ffffff', // Clean white background
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap' // Allows wrapping on smaller screens
        }}
      >
        <h1
          style={{
            marginTop: '10px'
          }}
        >
          Browse All Parts
        </h1>
        <div
          style={{
            marginLeft: 'auto', // Pushes this div to the right
            display: 'flex',
            alignItems: 'center',
            gap: '10px', // Space between label and select
            marginTop: '10px' // Adjusts spacing for smaller screens
          }}
        >
          <label
            htmlFor="manufacturer-select"
            style={{
              marginRight: '10px',
              fontSize: '16px',
              fontWeight: '500',
              color: '#555'
            }}
          >
            Filter by Manufacturer:
          </label>
          <select
            id="manufacturer-select"
            value={manufacturer}
            onChange={handleManufacturerChange}
            style={{
              padding: '10px',
              fontSize: '16px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              minWidth: '180px',
              height: '45px',
              backgroundColor: '#f9f9f9',
              cursor: 'pointer',
              transition: 'border 0.3s'
            }}
          >
            <option value="All">All Manufacturers</option>
            {manufList.map(manuf => (
              <option key={manuf.id} value={manuf.abbrname}>
                {manuf.abbrname}
              </option>
            ))}
          </select>
        </div>
      </header>

      {error && (
        <p style={{ marginLeft: '40px', fontSize: '20px', color: 'red' }}>
          {error}
        </p>
      )}

      {!error && searchResults.length > 0 && (
        <div>
          <Table className="table-bordered table-striped">
            <thead>
              <tr>
                <th>Part Number</th>
                <th>Manufacturer</th>
                <th>Conditions</th>
                <th>Price</th>
                <th>Pictures</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map(part => (
                <tr
                  key={`${part.partnum}-${part.manuf}`}
                  onClick={() =>
                    navigate(
                      `/partmatcher/partsearch?partNumber=${encodeURIComponent(part.partnum)}&description=${encodeURIComponent('Browse Search')}`
                    )
                  }
                >
                  <td>{part.partnum}</td>
                  <td>{part.abbrname}</td>
                  <td>{part.conditions}</td>
                  <td>{part.price}</td>
                  <td
                    style={{
                      width: '118px',
                      justifyContent: 'center'
                    }}
                  >
                    <TableCarousel part={part} />
                  </td>
                  <td
                    style={{
                      width: '118px',
                      justifyContent: 'center'
                    }}
                  >
                    <Button
                      variant="primary"
                      onClick={() =>
                        navigate(
                          `/partmatcher/partsearch?partNumber=${encodeURIComponent(part.partnum)}&description=${encodeURIComponent('Browse Search')}`
                        )
                      }
                    >
                      View Parts
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div
            className="row"
            style={{
              marginBottom: '10px'
            }}
          >
            <div className="col-3 col-lg-4 d-flex justify-content-start">
              <Button
                onClick={() => setPage(prev => Math.max(prev - 1, 1))}
                disabled={page === 1}
              >
                &lt; Previous
              </Button>
            </div>
            <div className="col-3 col-lg-4 d-flex justify-content-center">
              <span>Page {page}</span>
            </div>
            <div className="col-4 col-lg-4 d-flex justify-content-end ">
              <Button
                onClick={() => setPage(prev => prev + 1)}
                disabled={partCount < 50}
                style={{ paddingLeft: '30px', paddingRight: '25px' }} // Symmetrical padding
              >
                Next &gt;
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BrowsePart;
