import React, { useEffect } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';
import BackgroundCard from '../components/BackgroundCard';
import AboutUs from '../components/AboutUs';
import GettingStarted from '../components/GettingStarted';

function HomePage() {
  const { accessToken, logoutMessage, setLogoutMessage } = useAuth();

  const isLoggedOut = Boolean(!accessToken);

  useEffect(() => {
    // Log when useEffect runs due to logoutMessage changes
    if (logoutMessage) {
      // Set a timeout to clear the message after a set duration
      const timer = setTimeout(() => {
        setLogoutMessage('');
      }, 5000); // Clear the message after 5 seconds

      // Clean up the timeout when the component is unmounted or when logoutMessage changes again before the timeout completes
      return () => clearTimeout(timer);
    }
    return () => {}; // Noop function for ESLint
  }, [logoutMessage, setLogoutMessage]);

  return (
    <div>
      {logoutMessage && (
        <Alert variant="success" className="text-center">
          {logoutMessage}
        </Alert>
      )}

      <Container className="py-3">
        {/* New Row for Clickable Boxes */}
        <Row className="text-center" style={{ marginTop: '20px' }}>
          {/* Box 1 */}
          <Col xs={12} md={4} className="mb-4">
            <BackgroundCard
              url={
                isLoggedOut
                  ? '/partmatcher/guestpartsearch'
                  : '/partmatcher/partsearch'
              }
              imgSrc="/images/headers/Parts_Network_Header.jpeg"
              altText="Search Parts"
              title="Search Parts"
              text="Explore our extensive range of construction parts from top dealers."
            />
          </Col>
          <Col xs={12} md={4} className="mb-4">
            <BackgroundCard
              url={
                isLoggedOut
                  ? '/partmatcher/guestbrowseparts'
                  : '/partmatcher/browseparts'
              }
              imgSrc="/images/headers/Marketplace-Solution-Header.jpeg"
              altText="Browse Parts"
              title="Browse Parts"
              text="Browse our full range of parts."
            />
          </Col>
          <Col xs={12} md={4} className="mb-4">
            <BackgroundCard
              url={
                isLoggedOut
                  ? '/forsale/guestattachments'
                  : '/forsale/attachments'
              }
              imgSrc="/images/galleries/altorfer/Altorfer_Gallery_26.jpeg"
              altText="Search Attachments"
              title="Search Attachments"
              text="Search through our full range of attachments."
            />
          </Col>
        </Row>
        <Row className="text-center" style={{ marginTop: '20px' }}>
          {/* Box 1 */}
          <Col xs={12} md={4} className="mb-4">
            <BackgroundCard
              url={isLoggedOut ? '/forsale/guestengines' : '/forsale/engines'}
              imgSrc="/images/headers/HeavyQuip_Header.jpeg"
              altText="Browse Engines"
              title="Search Engines"
              text="Search through our full range of Engines."
            />
          </Col>
          <Col xs={12} md={4} className="mb-4">
            <BackgroundCard
              url={isLoggedOut ? '/forsale/guestmachines' : '/forsale/machines'}
              imgSrc="/images/galleries/thompson/Thompson_Carousel_2.jpeg"
              altText="Search Machines"
              title="Search Machines"
              text="Search through our full range of Machines"
            />
          </Col>
          <Col xs={12} md={4} className="mb-4">
            <BackgroundCard
              url={
                isLoggedOut ? '/forsale/guestotheritems' : '/forsale/otheritems'
              }
              imgSrc="/images/galleries/thompson/Thompson_Carousel_12.jpeg"
              altText="Search Other"
              title="Other Items"
              text="Search through all of our other for sale items."
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            lg={6}
            xl={5}
            style={{ marginRight: '10px', marginTop: '30px' }}
          >
            <img
              id="Home-Page-logo"
              src="/images/logomock.png"
              alt="Construction Parts Network Logo"
              style={{
                maxWidth: '100%',
                maxHeight: '100%'
              }}
            />
          </Col>
          <Col xs={12} lg={5} xl={6} style={{ marginTop: '30px' }}>
            <AboutUs />
          </Col>
        </Row>
        {isLoggedOut && (
          <Row style={{ marginTop: '30px' }}>
            <Col xs={12} md={6} lg={6} className="mb-4">
              <GettingStarted />
            </Col>
            <Col
              xs={12}
              md={6}
              lg={5}
              className="mb-4"
              style={{ marginTop: '30px' }}
            >
              <BackgroundCard
                url="/register"
                imgSrc="/images/galleries/thompson/Thompson_Carousel_3.jpeg"
                altText="Search Other"
                title="Create an Account"
                text=""
              />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default HomePage;
