import { useState } from "react";
import { useAuth } from "../../../../frontend/src/context/AuthContext";
import { isTokenExpired } from "../../../../frontend/src/utils/jwtUtils";
import refreshAccessToken from "../../../../frontend/src/utils/refreshToken";

const attachmentHook = (baseUrl) => {
  const { accessToken, refreshToken, updateTokens, logout } = useAuth();
  const [searchResults, setSearchResults] = useState(null);
  const [error, setError] = useState("");
  const [filenames, setFilenames] = useState([]);
  const [picurls, setPicurls] = useState([]);

  const searchAttachments = async (attachmentId) => {
    let currentAccessToken = accessToken;

    // Check and refresh the token if it's expired
    if (!currentAccessToken || isTokenExpired(currentAccessToken)) {
      const refreshedTokenData = await refreshAccessToken(
        baseUrl,
        refreshToken,
        updateTokens,
        logout
      );
      if (!refreshedTokenData) {
        // Instead of navigating directly, call the logout function
        logout("Your session has expired. Please log in again.");
        return;
      }
      currentAccessToken = refreshedTokenData.accessToken;
    }

    setError(""); // Clear any existing error message
    setSearchResults(null); // Clear previous search results
    setFilenames([]);
    setPicurls([]);

    try {
      let url = `${baseUrl}/api/attachments/${attachmentId}`;

      const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${currentAccessToken}`,
        },
      });
      const data = await response.json();

      // Check for a successful response with parts data
      if (response.ok && data.data && data.data.attachment) {
        setSearchResults(data.data.attachment);
        setFilenames(data.data.filenames);
        setPicurls(data.data.picurls);

        if (data.data.picurls > 0) {
          setPicurls(data.data.picurls);
          setFilenames([]);
        } else if (data.data.filenames.length > 0) {
          setFilenames(data.data.filenames);
          setPicurls([]);
        }
        // } else {
        //   setErrorPic('ERROR_PIC_NOT_FOUND');
        // }
      } else {
        // Handle API errors by displaying a message from the errors array if available
        const errorMessage = data.errors?.length
          ? data.errors[0]
          : "Failed to fetch results";
        setError(errorMessage);
      }
    } catch (err) {
      setError("Failed to fetch results");
      // eslint-disable-next-line no-console
      console.error(err); // Log the error for debugging purposes
    }
  };

  return {
    searchResults,
    filenames,
    picurls,
    error,
    searchAttachments,
  };
};

export default attachmentHook;
