import React from 'react';

function ContactUs() {
  return (
    <div className="contact mb-5">
      <h2>Contact Us</h2>
      <p>
        Please contact us if you are interested in learning more about our parts
        network, other services, or if you&apos;re interested in working with us
        on a solution for your company or industry. If you have any questions,
        please let us know.
      </p>
      <p>
        NetCom Services LLC
        <br />
        +1 (800) 775 2962
        <br />
        netcomservicesllc@yahoo.com
      </p>
    </div>
  );
}

export default ContactUs;
