import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Masthead from '../../components/Masthead';
import ContactUs from '../../components/ContactUs';
import GalleriesCarousel from '../../components/GalleriesCarousel';
import CarterCarousel from '../../components/Galleries/CarterCarousel';

function CarterMachineryGalleryPage() {
  return (
    <div>
      <Masthead
        backgroundImage="/images/headers/Carter_Header.jpeg"
        headerText="Carter Machinery Gallery"
        headerSubtext=""
      />
      <Container className="py-3">
        <Row className="mt-4 mb-5">
          <Col xs={12} lg={7} xl={8}>
            <CarterCarousel />
          </Col>
          <Col xs={12} lg={5} xl={4} className="mt-4">
            <ContactUs />
            <GalleriesCarousel />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CarterMachineryGalleryPage;
