import React from 'react';
import { Container } from 'react-bootstrap';
import ListAttachment from '../../components/ForSale/ListAttachment';

function MachinesForSalePage() {
  const category = 103;

  return (
    <div>
      <Container className="py-3">
        <h1 className="pb-3">Machines For Sale</h1>
        <ListAttachment category={category} />
      </Container>
    </div>
  );
}

export default MachinesForSalePage;
