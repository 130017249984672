const getRandomLetter = () => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  return letters.charAt(Math.floor(Math.random() * letters.length));
};

// Helper function to generate a random 10-letter word
export const generateRandomWord = () => {
  let word = '';
  for (let i = 0; i < 10; i += 1) {
    word += getRandomLetter();
  }
  return word;
};

// Helper function to generate two random 5-letter words
export const generateTwoRandomWords = () =>
  `${generateRandomWord().substring(0, 5)} ${generateRandomWord().substring(0, 5)}`;

// Function to get a random company name (either one 10-letter word or two 5-letter words)
export const getRandomCompanyName = () =>
  Math.random() > 0.5 ? generateRandomWord() : generateTwoRandomWords();
