import React from 'react';
import { Container } from 'react-bootstrap';
import ListAttachment from '../../components/ForSale/GuestListAttachment';

function TransmissionsGuestForSalePage() {
  const category = 105;

  return (
    <div>
      <Container className="py-3">
        <h1 className="pb-3">Transmission For Sale</h1>
        <ListAttachment category={category} />
      </Container>
    </div>
  );
}

export default TransmissionsGuestForSalePage;
