import React from 'react';
import { Carousel } from 'react-bootstrap';

function YellowstoneCarousel() {
  return (
    <Carousel>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Header.jpeg"
            alt="YellowstoneGallery1"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_2.jpeg"
            alt="YellowstoneGallery2"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_3.jpeg"
            alt="YellowstoneGallery3"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_4.jpeg"
            alt="YellowstoneGallery4"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_5.jpeg"
            alt="YellowstoneGallery5"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_6.jpeg"
            alt="YellowstoneGallery6"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_7.jpeg"
            alt="YellowstoneGallery7"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_8.jpeg"
            alt="YellowstoneGallery8"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_9.jpeg"
            alt="YellowstoneGallery9"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_10.jpeg"
            alt="YellowstoneGallery10"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_11.jpeg"
            alt="YellowstoneGallery11"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_12.jpeg"
            alt="YellowstoneGallery12"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_13.jpeg"
            alt="YellowstoneGallery13"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_14.jpeg"
            alt="YellowstoneGallery14"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_15.jpeg"
            alt="YellowstoneGallery15"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_16.jpeg"
            alt="YellowstoneGallery16"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_17.jpeg"
            alt="YellowstoneGallery17"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_18.jpeg"
            alt="YellowstoneGallery18"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_19.jpeg"
            alt="YellowstoneGallery19"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_20.jpeg"
            alt="YellowstoneGallery20"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_21.jpeg"
            alt="YellowstoneGallery21"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_22.jpeg"
            alt="YellowstoneGallery22"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_23.jpeg"
            alt="YellowstoneGallery23"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_24.jpeg"
            alt="YellowstoneGallery24"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_25.jpeg"
            alt="YellowstoneGallery25"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_26.jpeg"
            alt="YellowstoneGallery26"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_27.jpeg"
            alt="YellowstoneGallery27"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_28.jpeg"
            alt="YellowstoneGallery28"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_29.jpeg"
            alt="YellowstoneGallery29"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_30.jpeg"
            alt="YellowstoneGallery30"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_31.jpeg"
            alt="YellowstoneGallery31"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_32.jpeg"
            alt="YellowstoneGallery32"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_33.jpeg"
            alt="YellowstoneGallery33"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_34.jpeg"
            alt="YellowstoneGallery34"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_35.jpeg"
            alt="YellowstoneGallery35"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_36.jpeg"
            alt="YellowstoneGallery36"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_37.jpeg"
            alt="YellowstoneGallery37"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_38.jpeg"
            alt="YellowstoneGallery38"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_39.jpeg"
            alt="YellowstoneGallery39"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_40.jpeg"
            alt="YellowstoneGallery40"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_41.jpeg"
            alt="YellowstoneGallery41"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_42.jpeg"
            alt="YellowstoneGallery42"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_43.jpeg"
            alt="YellowstoneGallery43"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_44.jpeg"
            alt="YellowstoneGallery44"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_45.jpeg"
            alt="YellowstoneGallery45"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_46.jpeg"
            alt="YellowstoneGallery46"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_47.jpeg"
            alt="YellowstoneGallery47"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_48.jpeg"
            alt="YellowstoneGallery48"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_49.jpeg"
            alt="YellowstoneGallery49"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_50.jpeg"
            alt="YellowstoneGallery50"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_51.jpeg"
            alt="YellowstoneGallery51"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_52.jpeg"
            alt="YellowstoneGallery52"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_53.jpeg"
            alt="YellowstoneGallery53"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_54.jpeg"
            alt="YellowstoneGallery54"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_55.jpeg"
            alt="YellowstoneGallery55"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_56.jpeg"
            alt="YellowstoneGallery56"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_57.jpeg"
            alt="YellowstoneGallery57"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_58.jpeg"
            alt="YellowstoneGallery58"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_59.jpeg"
            alt="YellowstoneGallery59"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_60.jpeg"
            alt="YellowstoneGallery60"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_61.jpeg"
            alt="YellowstoneGallery61"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_62.jpeg"
            alt="YellowstoneGallery62"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_63.jpeg"
            alt="YellowstoneGallery63"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_64.jpeg"
            alt="YellowstoneGallery64"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_65.jpeg"
            alt="YellowstoneGallery65"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_66.jpeg"
            alt="YellowstoneGallery66"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_67.jpeg"
            alt="YellowstoneGallery67"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_68.jpeg"
            alt="YellowstoneGallery68"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_69.jpeg"
            alt="YellowstoneGallery69"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_70.jpeg"
            alt="YellowstoneGallery70"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_71.jpeg"
            alt="YellowstoneGallery71"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_72.jpeg"
            alt="YellowstoneGallery72"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_73.jpeg"
            alt="YellowstoneGallery73"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_74.jpeg"
            alt="YellowstoneGallery74"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_75.jpeg"
            alt="YellowstoneGallery75"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_76.jpeg"
            alt="YellowstoneGallery76"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_77.jpeg"
            alt="YellowstoneGallery77"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_78.jpeg"
            alt="YellowstoneGallery78"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_79.jpeg"
            alt="YellowstoneGallery79"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_80.jpeg"
            alt="YellowstoneGallery80"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_81.jpeg"
            alt="YellowstoneGallery81"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_82.jpeg"
            alt="YellowstoneGallery82"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_83.jpeg"
            alt="YellowstoneGallery83"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_84.jpeg"
            alt="YellowstoneGallery84"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.yvpe.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/yellowstone/Yellowstone_Carousel_85.png"
            alt="YellowstoneGallery85"
          />
        </a>
      </Carousel.Item>
    </Carousel>
  );
}

export default YellowstoneCarousel;
