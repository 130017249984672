// Helper function to handle token refresh failure
function handleTokenRefreshFailure(logout) {
  logout('Your session expired. Please log in again.');
}

const refreshAccessToken = async (
  baseUrl,
  refreshToken,
  updateTokens,
  logout,
  newUserCompanyId = undefined
) => {
  try {
    // this will conditionally add companyId to the body if it is defined, won't if it is undefined.
    const body = {
      refreshToken
    };
    if (newUserCompanyId) {
      if (!/^\d+$/.test(newUserCompanyId)) {
        throw new Error(
          `Invalid company ID: newUserCompanyId must be a number.`
        );
      }
      body.newUserCompanyId = newUserCompanyId;
    }

    const response = await fetch(`${baseUrl}/api/refresh-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    const data = await response.json();

    if (response.ok) {
      // Update both access token and refresh token
      // DW: took me a long time to figure out that I needed
      // to have data.data to get the values from the response
      updateTokens(data.data.accessToken, data.data.refreshToken);

      return {
        accessToken: data.data.accessToken,
        refreshToken: data.data.refreshToken
      };
    }
    handleTokenRefreshFailure(logout);
    return null; // Indicate failure by returning null
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error refreshing access token:', error);
    handleTokenRefreshFailure(logout);
    return null; // Indicate failure by returning null
  }
};

export default refreshAccessToken;
