import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function AboutUs() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>About Us</h1>
          <p>
            {' '}
            Welcome to Construction Parts Network, your premier destination for
            browsing and purchasing top-quality construction parts from the
            leading dealers within the Netcom network. Whether youre a
            professional contractor, an independent parts dealer, or anyone in
            need of reliable construction components, we are here to connect you
            with the best suppliers worldwide.
          </p>
          <p>
            {' '}
            Our Mission At Construction Parts Network, our mission is simple yet
            powerful: to help anyone and everyone find the construction parts
            they need, no matter where they are in the world. We strive to make
            the process of sourcing construction materials seamless, efficient,
            and accessible to all.{' '}
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default AboutUs;
