import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function TestimonialAltorferCat() {
  return (
    <Container className="testimonials_container mb-3">
      <Row>
        <Col>
          <img
            className="testimonials_image float-end ps-3"
            src="/images/Testimonials/AltorferTestimonial.jpeg"
            alt="Chuck Stewart, Altorfer Cat"
          />
          <p>
            “Ray has built a network of trusted dealers that makes buying and
            selling less complicated in today&apos;s fast paced, information
            intensive world. NetCom Services&apos; (free from distractions)
            parts locator and inventory programs allow me to focus on the
            search, locating parts in my own inventory and other dealers
            inventory across North America instantly.” - Chuck Stewart, Used
            Parts Manger, Altorfer Cat
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default TestimonialAltorferCat;
