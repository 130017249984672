import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function TestimonialThompsonMachinery() {
  return (
    <Container className="testimonials_container pb-3">
      <Row>
        <Col>
          <img
            className="testimonials_image float-end ps-3"
            src="/images/testimonials/ThompsonMachineryTestimonial.jpeg"
            alt="Art Young, Thompson Machinery"
          />
          <p>
            &quot;I have been using NetCom Services for over 20 years with
            exceptional results. The system is fast, easy to use and I often
            find the part and make the sale within minutes. Ray and his staff
            are friendly, knowledgeable and always willing to help should you
            need assistance. The folks at NetCom Services are an integral part
            of my success in Used Parts and I am very fortunate to have them on
            my team.&quot; - Art Young, Used Parts Manager, Thompson Machinery
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default TestimonialThompsonMachinery;
