import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../context/AuthContext';

function PublicRoute({ children }) {
  const { accessToken } = useAuth();
  const [isChecking, setIsChecking] = useState(true);
  const location = useLocation();

  useEffect(() => {
    // Check if there is an access token directly without trying to refresh it
    setIsChecking(false);
  }, []);

  if (isChecking) {
    return <div>Loading...</div>;
  }

  if (accessToken) {
    // Redirect to home page or another appropriate page if logged in
    const redirectPath = location.state?.from?.pathname || '/';
    return <Navigate to={redirectPath} replace />;
  }

  return children; // Render children routes if the user is not logged in
}

PublicRoute.propTypes = {
  children: PropTypes.node.isRequired
};

export default PublicRoute;
