import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function TestimonialThompsonTractor() {
  return (
    <Container className="testimonials_container pb-3 pt-3">
      <Row>
        <Col>
          <img
            className="testimonials_image float-start pe-3"
            src="/images/testimonials/ThompsonTestimonial.png"
            alt="Thompson Parts Solutions"
          />
          <p>
            “As our Industry and Business models have significantly changed over
            the last 30+ years, there has remained a constant in our industry,
            and that is NetCom Services. Hands down one of the most effective
            tools in our industry for buying and selling parts. Simple to use
            and highly effective. NetCom has certainly changed with the times,
            BUT their quality and dependability have remained exceptional.
            Anyone wishing to become a member is vetted by Ray and the NetCom
            team to ensure that only the &apos;best of the best&apos; can join.
            NetCom has played a significant role in our success by offering us
            the best platform in the industry to buy and sell our parts and
            components, and they&apos;ll do the same for you. Thank you Ray!”
            -Tommy Riggs, Manager, Thompson Parts Solutions
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default TestimonialThompsonTractor;
