import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import guestListAttachmentHook from '@netcom-network-v2/shared/src/hooks/guestListAttachmentHook';
import filterListHook from '@netcom-network-v2/shared/src/hooks/filterListHook';
import calculateButtonIndices from '../../utils/calculateButtonIndices'; // Import the helper function
import { getRandomCompanyName } from '../../utils/RandomCompanyName';

function ListAttachment({ category }) {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { searchResults, error, count, listAttachments } =
    guestListAttachmentHook(baseUrl);
  const { subCategoryList, filterList } = filterListHook(baseUrl);
  const navigate = useNavigate();
  const sessionStorageKey = `listAttachmentState-${category}`;

  // Initialize from session storage or default values
  const initialState = JSON.parse(
    sessionStorage.getItem(sessionStorageKey)
  ) || {
    sortConfig: { field: null, direction: null },
    modelNum: '',
    companyMod: '',
    subCategoryMod: '',
    currentPage: 1
  };
  const openCreateAccount = () => {
    navigate('/register');
  };
  const [sortConfig, setSortConfig] = useState(initialState.sortConfig);
  const [modelNum, setModelNum] = useState(initialState.modelNum);
  const [subCategoryMod, setSubCategoryMod] = useState(
    initialState.subCategoryMod
  );
  const limit = 50;

  // need to keep companyMod even though its not technically used- filterList uses it.
  const [companyMod] = useState(initialState.companyMod);
  const [currentPage, setCurrentPage] = useState(initialState.currentPage);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 576);
  const [itemsPerPage] = useState(25);
  let indexOfLastItem = currentPage * itemsPerPage;
  const totalPages = Math.ceil(count / limit);
  const redirectToDetailsPage = (id, modelnum) => {
    const detailsPageUrl = `/forsale/guestattachments/${id}/${modelnum}`;
    window.location.href = detailsPageUrl;
  };

  useEffect(() => {
    const sortParam =
      sortConfig.field && sortConfig.direction
        ? `${sortConfig.field}${sortConfig.direction}`
        : null;

    filterList(companyMod, subCategoryMod, category);

    listAttachments(
      category,
      sortParam,
      subCategoryMod !== 'All Categories' ? subCategoryMod : undefined,
      modelNum,
      currentPage,
      limit
    );
  }, [category, sortConfig, companyMod, subCategoryMod, modelNum, currentPage]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 576);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const randomCompanyName = getRandomCompanyName();

  const handleInputChange = e => {
    const formattedInput = e.target.value
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, '');
    setModelNum(formattedInput);
    setCurrentPage(1); // Reset to first page when modelNum changes
  };

  const handleSort = field => {
    setSortConfig(prevConfig => {
      if (prevConfig.field === field) {
        return {
          field,
          direction: prevConfig.direction === 'asc' ? 'desc' : 'asc'
        };
      }
      return { field, direction: 'asc' };
    });
  };

  const getSortIcon = field => {
    if (sortConfig.field === field) {
      return sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />;
    }
    return null;
  };

  const handlePageChange = page => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
      window.scrollTo(0, 0);
    }
  };

  if (count < indexOfLastItem) {
    indexOfLastItem = count;
  }
  const prevSubCategoryMod = useRef(subCategoryMod);
  const prevModelNum = useRef(modelNum);

  // Handle changes in companyMod or subCategoryMod - resets current page if these change, maintains page on price and datemod changes
  useEffect(() => {
    if (
      prevSubCategoryMod.current !== subCategoryMod ||
      prevModelNum.current !== modelNum
    ) {
      setCurrentPage(1);
    }
    // Update refs to the latest values
    prevSubCategoryMod.current = subCategoryMod;
    prevModelNum.current = modelNum;
  }, [modelNum, subCategoryMod]);

  // Saving current State
  useEffect(() => {
    const stateToSave = JSON.stringify({
      sortConfig,
      modelNum,
      subCategoryMod,
      currentPage
    });
    sessionStorage.setItem(sessionStorageKey, stateToSave);
  }, [sortConfig, modelNum, subCategoryMod, currentPage]);

  const buttonIndices = useMemo(
    () => calculateButtonIndices(searchResults.length),
    [searchResults.length]
  );
  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div className="row" style={{ marginBottom: '20px' }}>
          <div className="col-8 col-lg-4">
            <p style={{ marginBottom: '5px', fontWeight: 500 }}>
              Search By Model Number
            </p>
            <input
              type="text"
              value={modelNum}
              onChange={handleInputChange}
              placeholder="Enter Model Number"
              style={{ width: '275px', height: '33px', padding: '2' }}
            />
          </div>
          {![101, 104, 105, 106].includes(category) && (
            <div className="col-6 col-lg-3">
              <p style={{ marginBottom: '5px', fontWeight: 500 }}>
                Filter By Category
              </p>
              <select
                value={subCategoryMod}
                onChange={e => setSubCategoryMod(e.target.value)}
                style={{
                  width: '200px',
                  padding: '5px 10px'
                }}
              >
                <option value="All Categories">All Categories</option>
                {subCategoryList &&
                  subCategoryList.map(subCategories => (
                    <option
                      key={subCategories.subcat_name}
                      value={subCategories.subcat_name}
                    >
                      {subCategories.subcat_name}
                    </option>
                  ))}
              </select>
            </div>
          )}
        </div>

        <div className="row" style={{ marginBottom: '15px' }}>
          <p style={{ marginBottom: '5px', fontWeight: 500 }}>
            Displaying page {currentPage} of {totalPages}, total results:{' '}
            {count}
            {[
              modelNum && ` for a Model Number that begins with ${modelNum}`,
              subCategoryMod &&
                subCategoryMod !== 'All Categories' &&
                ` from the Category: ${subCategoryMod}`
            ]
              .filter(Boolean)
              .join(', ')
              .replace(/, ([^,]*)$/, ', and $1')}
          </p>
        </div>

        <Table className="table-bordered table-striped">
          <thead>
            <tr>
              <th
                className={`ForSaleth ${isSmallScreen ? 'hide-on-small-screen' : ''}`}
              >
                Company Name
              </th>
              <th className="ForSaleth">Manufacturer</th>
              <th
                className="sortable-header ForSaleth"
                onClick={() => handleSort('model')}
              >
                Model Number {getSortIcon('model')}
              </th>
              <th className="ForSaleth">Condition</th>
              {![101, 104, 105, 106].includes(category) && (
                <th
                  className={`ForSaleth ${isSmallScreen ? 'hide-on-small-screen' : ''}`}
                >
                  Category
                </th>
              )}
              <th className="ForSaleth">Price</th>

              <th
                className="sortable-header ForSaleth"
                onClick={() => handleSort('date')}
              >
                Date Modified {getSortIcon('date')}
              </th>
            </tr>
          </thead>
          <tbody>
            {searchResults?.map((attachment, index) => (
              <tr key={attachment.id}>
                {buttonIndices.has(index) ? (
                  <td
                    style={{
                      position: 'relative',
                      overflow: 'hidden'
                    }}
                  >
                    <button
                      onClick={openCreateAccount}
                      type="button"
                      style={{
                        all: 'unset',
                        cursor: 'pointer',
                        display: 'block',
                        width: '100%',
                        textAlign: 'center',
                        padding: '5px 0',
                        fontSize: '16px',
                        color: '#007BFF',
                        textDecoration: 'underline',
                        position: 'relative',
                        zIndex: 2
                      }}
                    >
                      Create Account to View
                    </button>
                  </td>
                ) : (
                  <td
                    className={`${isSmallScreen ? 'hide-on-small-screen' : ''}`}
                    style={{
                      filter: 'blur(4px)',
                      color: '#000', // Text color
                      textAlign: 'left',
                      padding: '5px 0',
                      fontSize: '16px'
                    }}
                  >
                    {randomCompanyName}
                  </td>
                )}

                <td>{attachment.manuflong}</td>
                <td className="clickable-column">
                  <button
                    type="button"
                    onClick={() =>
                      redirectToDetailsPage(attachment.id, attachment.modelnum)
                    }
                    style={{
                      background: 'none',
                      border: 'none',
                      color: '#4986c7',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      padding: 0,
                      font: 'inherit'
                    }}
                  >
                    {attachment.modelnum}
                  </button>
                </td>
                <td>{attachment.condlong}</td>
                {![101, 104, 105, 106].includes(category) && (
                  <td
                    className={`${isSmallScreen ? 'hide-on-small-screen' : ''}`}
                  >
                    {attachment.subcategory}
                  </td>
                )}

                {buttonIndices.has(index) ? (
                  <td
                    style={{
                      position: 'relative',
                      overflow: 'hidden'
                    }}
                  >
                    <button
                      onClick={openCreateAccount}
                      type="button"
                      style={{
                        all: 'unset',
                        cursor: 'pointer',
                        display: 'block',
                        width: '100%',
                        textAlign: 'center',
                        padding: '5px 0',
                        fontSize: '16px',
                        color: '#007BFF',
                        textDecoration: 'underline',
                        position: 'relative',
                        zIndex: 2
                      }}
                    >
                      Create Account to View
                    </button>
                  </td>
                ) : (
                  <td
                    style={{
                      filter: 'blur(6px)',
                      textAlign: 'center'
                    }}
                  >
                    <img
                      src="/images/justprice.png"
                      alt="Thumbnail for price"
                      style={{
                        width: '60%',
                        height: '60%'
                      }}
                    />
                  </td>
                )}
                <td
                  className={`${isSmallScreen ? 'hide-on-small-screen' : ''}`}
                >
                  {attachment.datemod}
                </td>
              </tr>
            ))}
            {error && (
              <tr>
                <td colSpan="7">Error: {error}</td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="row" style={{ marginBottom: '5px' }}>
          <div className="col-3 col-lg-4 d-flex justify-content-start">
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt; Previous
            </Button>
          </div>
          <div className="col-3 col-lg-4 d-flex justify-content-center">
            <span>
              Page {currentPage} of {totalPages}
            </span>
          </div>
          <div className="col-3 col-lg-4 d-flex justify-content-end ">
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              style={{ paddingLeft: '30px', paddingRight: '25px' }} // Symmetrical padding
            >
              Next &gt;
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

ListAttachment.propTypes = {
  category: PropTypes.number.isRequired
};

export default ListAttachment;
