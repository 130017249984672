import React from 'react';
import { Carousel } from 'react-bootstrap';

function MiltonCarousel() {
  return (
    <Carousel>
      <Carousel.Item>
        <a
          href="https://www.miltoncat.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/milton/Milton_Gallery_1.jpeg"
            alt="MiltonGallery1"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.miltoncat.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/milton/Milton_Gallery_2.jpeg"
            alt="MiltonGallery2"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.miltoncat.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/milton/Milton_Gallery_3.jpeg"
            alt="MiltonGallery3"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.miltoncat.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/milton/Milton_Gallery_4.jpeg"
            alt="MiltonGallery4"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.miltoncat.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/milton/Milton_Gallery_5.jpeg"
            alt="MiltonGallery5"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.miltoncat.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/milton/Milton_Gallery_6.jpeg"
            alt="MiltonGallery6"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.miltoncat.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/milton/Milton_Gallery_7.jpeg"
            alt="MiltonGallery7"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.miltoncat.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/milton/Milton_Gallery_8.jpeg"
            alt="MiltonGallery8"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.miltoncat.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/milton/Milton_Gallery_9.jpeg"
            alt="MiltonGallery9"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.miltoncat.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/milton/Milton_Gallery_10.jpeg"
            alt="MiltonGallery10"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.miltoncat.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/milton/Milton_Gallery_11.jpeg"
            alt="MiltonGallery11"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <a
          href="https://www.miltoncat.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="d-block w-100"
            src="/images/galleries/milton/Milton_Gallery_12.jpeg"
            alt="MiltonGallery12"
          />
        </a>
      </Carousel.Item>
    </Carousel>
  );
}

export default MiltonCarousel;
