import React from 'react';
import GuestBrowsePart from '../../components/GuestBrowseParts';

function GuestBrowsePartPage() {
  return (
    <div>
      <GuestBrowsePart />
    </div>
  );
}

export default GuestBrowsePartPage;
